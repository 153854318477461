<template>
  <div id="sprApp" @resize="onResize" class="white">
    <v-menu
        v-model="show_context_menu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
    >
      <v-list dense nav>
        <v-list-item dense style="font-size: .84rem"
                     @click="addColumnToLeft(0)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="grey darken-3" size="20">
              mdi-table-column-plus-before
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Вставити колонку зліва
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense style="font-size: .84rem"
                     @click="addColumnToLeft(1)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="grey darken-3" size="20">
              mdi-table-column-plus-after
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Вставити колонку справа
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense style="font-size: .84rem"
                     @click="deleteColumn"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="grey darken-3" size="20">
              mdi-table-column-remove
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Видалити колонку
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense style="font-size: .84rem"
                     @click="addRowToDown(1)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="grey darken-3" size="20">
              mdi-table-row-plus-before
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Вставити рядок вище
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense style="font-size: .84rem"
                     @click="addRowToDown(0)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="grey darken-3" size="20">
              mdi-table-row-plus-after
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Вставити рядок нижче
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense style="font-size: .84rem"
                     @click="deleteRow"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="grey darken-3" size="20">
              mdi-table-row-remove
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Видалити рядок
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense style="font-size: .84rem"
                     @click="cellUnion"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="grey darken-3" size="20">
              mdi-vector-union
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Об’єднати
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense style="font-size: .84rem"
                     @click="insertQR"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="grey darken-3" size="20">
              mdi-vector-union
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Вставити QR-код
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
        v-model="field_selection.show_field_menu"
        :position-x="x_field"
        :position-y="y_filed"
        absolute
        offset-y
        :close-on-content-click="false"
    >
      <v-card width="600" min-height="440" class="pa-2" style="overflow: hidden">
        <v-row>
          <v-col cols="12" md="5" class="grey lighten-4">
            <v-list nav dense style="height: 302px; overflow: auto; border-radius: 6px">
              <template
                  v-for="item in field_selection.group"
              >
                <v-list-item
                    :key="item.value"
                    dense
                    @click.stop="field_selection.current_group = item.value"
                    :class="field_selection.current_group === item.value ? 'grey lighten-4' : 'white'"
                    class="mb-0"
                >
                  <v-list-item-icon>
                    <v-icon size="20">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content style="font-size: .84rem">
                    {{ item.text }}
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="`sep-${item.value}`"/>
              </template>

            </v-list>
          </v-col>
          <v-col cols="12" md="7" class="grey lighten-4">
            <v-list nav dense style="height: 302px; overflow: auto; border-radius: 6px" v-if="current_field_select_list.length">
              <template
                  v-for="item in current_field_select_list"
              >
                <v-list-item
                    :key="item.value"
                    dense style="min-height: 32px"
                    class="mb-0"
                    @click.stop="onFieldSelect(item)"
                    :class="field_selection.current_field_value === item.value ? 'grey lighten-4' : 'white'"
                >
                  <v-list-item-content style="font-size: .84rem;" class="py-1">
                    {{ item.text }}
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="`sep-${item.value}`"/>
              </template>
            </v-list>
            <div v-else style="height: 302px; width: 100%; display: flex; align-items: center; justify-content: center" class="white pa-6">
              <div style="text-align: center; font-size: .95rem">
                Для відображення полів слід обрати групу зліва
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="height: calc(100% - 302px)">
          <v-col cols="12" class="d-flex">
            <div style="flex: 1; margin-right: 16px">
              <v-textarea hide-details filled dense label="Значення параметра" auto-grow rows="3"
                          color="grey darken-1" style="font-size: .86rem"
                          v-model="field_selection.current_field_value" />
            </div>
            <v-btn color="success" height="38" @click="saveFieldSelect">
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
    <v-menu
        v-model="format_selection.show_field_menu"
        :position-x="x_format"
        :position-y="y_format"
        absolute
        offset-y
        :close-on-content-click="false"
    >
      <v-card width="500" min-height="400" class="pa-2">
        <v-row style="height: 280px">
          <v-col cols="12">
            <v-tabs color="success">
              <v-tab>Число</v-tab>
              <v-tab>Дата</v-tab>
              <v-tab>Булево</v-tab>

              <v-tab-item class="mt-3 mb-3">
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                        label="Розрядність" v-integer
                        hide-details filled color="grey darken-2"
                        v-model="format_selection.number_format_precision"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-btn small block color="secondary" height="48" @click="buildNumberFormat">
                      Перенести
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="mt-3 mb-3">
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                        label="Формат дати" placeholder="%d.%m.%Y [26.04.2024]"
                        hide-details filled color="grey darken-2"
                        v-model="format_selection.date_format_value"
                        @change="parseFormatForMoment"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-btn small block color="secondary" height="48" @click="buildDateFormat">
                      Перенести
                    </v-btn>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                        label="Результат" v-integer
                        readonly
                        hide-details filled color="grey darken-2"
                        v-model="format_selection.date_format_text"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="secondary"
                            small block height="48"
                            v-bind="attrs"
                            v-on="on"
                        >
                          Перелік команд
                        </v-btn>
                      </template>
                      <v-list nav dense>
                        <v-list-item
                            v-for="(item, index) in format_selection.date_formats"
                            :key="index" dense @click.stop
                        >
                          <v-list-item-subtitle>{{ item }}</v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="mt-3 mb-3">
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                        label="Значення для 'Так'"
                        hide-details filled color="grey darken-2"
                        v-model="format_selection.boolean_true_value"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-btn small block color="secondary" height="48" @click="buildBooleanFormat">
                      Перенести
                    </v-btn>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                        label="Значення для 'Ні'"
                        hide-details filled color="grey darken-2"
                        v-model="format_selection.boolean_false_value"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row style="height: calc(100% - 280px)">
          <v-col cols="12" class="d-flex">
            <div style="flex: 1">
              <v-textarea hide-details dense filled label="Значення параметра"
                          auto-grow rows="3" class="mr-2"
                          color="grey darken-1" style="font-size: .86rem"
                          v-model="format_selection.current_field_value" />
            </div>
            <div style="flex: 0 0 36px">
              <v-btn color="success" height="38" @click="saveFormatSelect">
                OK
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
    <v-menu
        v-model="empty_selection.show_field_menu"
        :position-x="x_empty"
        :position-y="y_empty"
        absolute
        offset-y
        :close-on-content-click="false"
    >
      <v-card width="500" min-height="400" class="pa-2">
        <v-row style="height: 280px">
          <v-col cols="8">
            <v-text-field
                label="Пусте значення"
                hide-details filled color="grey darken-2"
                v-model="empty_selection.empty_value"
            />
          </v-col>
          <v-col cols="4">
            <v-btn small block color="secondary" height="48" @click="buildEmptyFormat">
              Перенести
            </v-btn>
          </v-col>
        </v-row>
        <v-row style="height: calc(100% - 280px)">
          <v-col cols="12" class="d-flex">
            <div style="flex: 1">
              <v-textarea hide-details dense filled label="Значення параметра"
                          auto-grow rows="3" class="mr-2"
                          color="grey darken-1" style="font-size: .86rem"
                          v-model="empty_selection.current_field_value" />
            </div>
            <div style="flex: 0 0 36px">
              <v-btn color="success" height="38" @click="saveEmptySelect">
                OK
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
    <v-menu
        v-model="additional_selection.show_field_menu"
        :position-x="x_additional"
        :position-y="y_additional"
        absolute
        offset-y
        :close-on-content-click="false"
    >
      <v-card width="500" min-height="384" class="pa-4">
        <v-row style="height: 246px">
          <v-col cols="12" sm="6" class="pb-2 pt-2"
                 v-for="(item, idx) in additional_selection.fields"
                 :key="`add-field-${idx}`"
          >
            <v-btn block small @click.stop="addFunctionToAdditional(item.value)">
              {{ item.text }}
            </v-btn>
          </v-col>
          <v-col cols="12">
              <v-select
                  label="Поле" filled dense hide-details
                  :items="field_selection.fields"
                  v-model="additional_selection.selected_field"
                  color="grey darken-1"
                  @change="addFieldToAdditional"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-icon>
                      <v-icon color="grey darken-2" size="20" class="mr-2">
                        {{ (field_selection.group.find(i => i.value === data.item.group) || {}).icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle v-html="`<span class='font-weight-medium success--text'>[ ${(field_selection.group.find(i => i.value === data.item.group) || {}).text} ]</span> ${data.item.text}`" />
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
          </v-col>
        </v-row>
        <v-row style="height: calc(100% - 246px)">
          <v-col cols="12" class="d-flex pb-1">
            <div style="flex: 1">
              <v-textarea hide-details dense filled label="Значення параметра"
                          auto-grow rows="4"
                          :id="`additional-textarea-${additional_selection.row_num}`"
                          color="grey darken-1" style="font-size: .86rem"
                          v-model="additional_selection.current_field_value" />
            </div>
          </v-col>
          <v-col cols="12" class="d-flex pt-1">
              <v-btn color="success" block height="38" @click="saveAdditionalSelect">
                OK
              </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
    <v-menu
        v-model="image_data.show_context_menu"
        :position-x="x_image"
        :position-y="y_image"
        absolute
        offset-y
        :close-on-content-click="false"
    >
      <v-card width="400" :min-height="image_data.type === 'custom_image' ? 320 : 270" class="pa-3">
        <v-tabs color="success">
          <v-tab>Налаштування</v-tab>
          <v-tab>Завантажити</v-tab>

          <v-tab-item class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-select v-model="image_data.type" :items="image_data.type_select" filled
                          label="Тип картинки" :class="image_data.type ? '' : 'req-star'"
                          color="grey darken-2" hide-details />
              </v-col>
              <v-col cols="12" v-if="image_data.type === 'custom_image'">
                <v-select v-model="image_data.selected_image" :items="image_data.images_select" filled
                          label="Картинка" :class="image_data.type ? '' : 'req-star'"
                          color="grey darken-2" hide-details
                          @change="onImageSelect"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="image_data.width" filled v-integer
                              label="Ширина (px)" :class="image_data.width ? '' : 'req-star'"
                              color="grey darken-2" hide-details
                />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="image_data.height" filled v-integer
                              label="Висота (px)" :class="image_data.height ? '' : 'req-star'"
                              color="grey darken-2" hide-details
                />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="image_data.left" filled v-integer
                              label="Зміщення ліворуч (px)" color="grey darken-2" hide-details
                />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="image_data.top" filled v-integer
                              label="Зміщення зверху (px)" color="grey darken-2" hide-details
                />
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-btn color="success" depressed @click.stop="buildImage" style="flex: 1" class="mr-2">
                  Зберегти
                </v-btn>
                <div style="flex: 0 0 36px">
                  <v-btn color="error" depressed @click.stop="deleteImage">
                    <v-icon>mdi-delete-empty-outline</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="mt-2">
            <v-row>
              <v-col cols="12">
                <v-file-input
                    accept="image/*"
                    label="Оберіть картинку"
                    filled hide-details
                    prepend-icon="mdi-camera"
                    v-model="file_qr"
                    color="grey darken-2"
                />
              </v-col>
              <v-col cols="12">
                <v-btn class="success" block depressed @click.stop="uploadImage">Завантажити картинку</v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>

      </v-card>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        stateless
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2 pb-2">
              <v-tabs color="success">
                <v-tab>Реквізити</v-tab>
                <v-tab>Параметри</v-tab>

                <v-tab-item class="mt-3 mb-3">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field type="text" hide-details filled label="Назва" v-model="element_name"
                                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                    :class="element_name ? '' : 'req-star'"
                                    color="grey"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-select :items="pageSizeTypes" hide-details filled label="Розмір сторінки" v-model="element_page_size"
                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="element_page_size ? '' : 'req-star'"
                                color="grey"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-integer type="text" hide-details filled label="К-сть на сторінку" v-model.number="element_items_by_page"
                                    color="grey"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-switch v-model="element_show_in_flat"
                                :label="element_show_in_flat ? 'Виводити у абонента' : 'Не виводити у абонента'"
                                hide-details color="success"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-alert
                          outlined
                          type="success"
                          text
                      >
                        Для примусового розподілу на сторінки слід прописати в будь-якій комірці рядка після якого має бути розподіл
                        <span class="font-weight-medium">@page_separator</span>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" class="d-flex">
                      <v-btn depressed text
                             color="secondary darken-1"
                             style="flex: 1; margin-right: 12px"
                             @click.stop="crudItem" :loading="getModalLoading"
                             class="button-accept">
                        <v-icon left>mdi-content-save</v-icon>
                        Зберегти
                      </v-btn>

                      <v-menu offset-y v-model="menu">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              width="32"
                              v-bind="attrs"
                              v-on="on"
                              :loading="getModalLoading"
                              :disabled="isNew"
                              @click="menu = true"
                              depressed class="grey lighten-4"
                              style="flex: 0 0 32px"
                          >
                            <v-icon color="error">mdi-delete-forever-outline</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense rounded nav>
                          <v-list-item link class="px-4" @click.stop="deleteDialog">
                            <v-list-item-icon>
                              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-card class="field-card field-card-header">
                        <div class="field-row font-weight-medium">
                          <div>Параметр</div>
                          <div>Адреса</div>
                          <div>Значення</div>
                          <div>Додатково</div>
                          <div>Формат</div>
                          <div>Не заповнено</div>
                          <div>
                            <v-menu bottom left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" small>
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list nav dense>
                                <v-list-item @click="fillParameters">
                                  <v-list-item-icon>
                                    <v-icon size="20" color="success" class="mr-2">mdi-plus-circle-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Заповнити
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </div>
                      </v-card>
                      <v-card
                          v-for="item in parameters_table"
                          :key="item.row_num"
                          class="field-card"
                      >
                        <div class="field-row">
                          <div>
                            {{ item.parameter }}
                          </div>
                          <div>
                            {{ item.short_address }}
                          </div>
                          <div>
                            <input type="text" v-model="item.value" :id="`field-for-select-${item.row_num}`" readonly @click="showFieldSelect">
                          </div>
                          <div>
                            <input type="text" v-model="item.additional" :id="`field-for-additional-${item.row_num}`" readonly @click="showAdditionalSelect">
                          </div>
                          <div>
                            <input type="text" v-model="item.format" :id="`field-for-format-${item.row_num}`" readonly @click="showFormatSelect">
                          </div>
                          <div>
                            <input type="text" v-model="item.empty" :id="`field-for-empty-${item.row_num}`" readonly @click="showEmptySelect">
                          </div>
                          <div>

                          </div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="commands" style="height: 38px; z-index: 3; position: sticky; top: 0; left: 0">
      <div class="font-buttons white pa-1 d-flex">
        <v-btn small depressed
               class="pa-0 default-font-button"
               :color="font_settings.bold ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyBoldStyle"
        >
          <v-icon size="18">mdi-format-bold</v-icon>
        </v-btn>
        <v-btn small depressed
               class="pa-0 default-font-button"
               :color="font_settings.italic ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyItalicStyle"
        >
          <v-icon size="18">mdi-format-italic</v-icon>
        </v-btn>
        <v-btn small depressed
               class="pa-0 default-font-button"
               :color="font_settings.underline ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyUnderlineStyle"
        >
          <v-icon size="18">mdi-format-underline</v-icon>
        </v-btn>
        <div class="font-size-input"
             v-click-outside="onFontSizeClickOutside">
          <input
              @click.stop="font_settings.show_font_options = true"
              :value="font_settings.size"
              @change="onFontSizeChange"
          >
          <div class="input-options elevation-2" v-if="font_settings.show_font_options">
            <div v-for="item in font_size_options" :key="item"
                 @click.stop="setFontSize(item)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="font-family-input"
             v-click-outside="onFontFamilyClickOutside"
        >
          <div class="font-family-title" @click.stop="font_settings.show_font_family_options = true">
            {{ font_settings.family }}
          </div>
          <div class="font-options elevation-2" v-if="font_settings.show_font_family_options">
            <div v-for="a in fonts_option" :key="a"
                 @click.stop="onFontFamilyClick(a)"
            >
              {{ a }}
            </div>
          </div>
        </div>
        <v-btn small depressed class="pa-0 default-font-button"
               style="margin-left: 6px"
               :color="font_settings.horizontal_align === 'left' ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyHorizontalLeft('left')"
        >
          <v-icon size="18">mdi-format-align-left</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button"
               :color="font_settings.horizontal_align === 'center' ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyHorizontalLeft('center')"
        >
          <v-icon size="18">mdi-format-align-center</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button"
               :color="font_settings.horizontal_align === 'right' ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyHorizontalLeft('right')"
        >
          <v-icon size="18">mdi-format-align-right</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button"
               :color="font_settings.horizontal_align === 'justify' ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyHorizontalLeft('justify')"
        >
          <v-icon size="18">mdi-format-align-justify</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button"
               :color="font_settings.vertical_align === 'bottom' ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyVerticalLeft('bottom')"
        >
          <v-icon size="18">mdi-format-align-bottom</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button"
               :color="font_settings.vertical_align === 'vcenter' ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyVerticalLeft('vcenter')"
        >
          <v-icon size="18">mdi-format-align-middle</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button"
               :color="font_settings.vertical_align === 'top' ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyVerticalLeft('top')"
        >
          <v-icon size="18">mdi-format-align-top</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button-color">
          <v-icon size="24">mdi-format-color-text</v-icon>
          <div>
            <v-menu v-model="font_settings.show_font_color" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div
                    class="color-square"
                    :style="`background-color: ${font_settings.color}`"
                    :class="{'color-picker-open': font_settings.show_font_color, 'color-picker-close': !font_settings.show_font_color}" v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker :value="font_settings.color"
                                  flat mode="hexa"
                                  @update:color.capture="onTextColorChange"
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button-color">
          <v-icon size="24">mdi-format-color-fill</v-icon>
          <div>
            <v-menu v-model="font_settings.show_background_color" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div
                    class="color-square"
                    :style="`background-color: ${font_settings.background_color}`"
                    :class="{'color-picker-open': font_settings.show_background_color, 'color-picker-close': !font_settings.show_background_color}" v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker :value="font_settings.background_color"
                                  flat mode="hexa"
                                  @update:color.capture="onBackgroundColorChange"
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button" id="border-settings-button" style="position:relative;"
               @click.stop="border_settings.show = true">
          <v-icon size="18">mdi-border-all</v-icon>
          <div class="border-settings elevation-2" v-if="border_settings.show">
            <div class="border-settings-row">
              <div class="border-settings-icon"
                   :class="border_settings.all.on ? 'grey' : 'grey lighten-2'"
              >
                <v-icon size="20" style="flex: 0 0 30px">
                  mdi-border-all
                </v-icon>
              </div>

              <div class="border-style-div">
                <div @click.stop="onBorderStyleClick('all')" class="border-style-line">
                  <div :style="`border-bottom: ${border_settings.all.style}; height: 3px; width: 100%;`">
                  </div>
                </div>
                <div class="border-style-options elevation-2" v-click-outside="borderStyleDivClickOutside"
                     v-if="border_settings.all.show_border_style">
                  <div class="pa-2"
                       v-for="(item, idx) in border_style_options" :key="idx"
                       @click="onBorderStyleElementClick('all', item)"
                  >
                    <div style="height: 3px"></div>
                    <div :style="item"></div>
                    <div style="height: 3px"></div>
                  </div>
                </div>
              </div>

              <div class="color-square-wrapper">
                <v-menu v-model="border_settings.all.show_border_color" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div
                        class="color-square"
                        :style="`background-color: ${border_settings.all.color}; pointer-events: ${border_settings.all.on ? 'all' : 'none'}`"
                        :class="{'color-picker-open': border_settings.all.show_border_color, 'color-picker-close': !border_settings.all.show_border_color}" v-on="on" />
                  </template>
                  <v-card class="dont-close">
                    <v-card-text class="pa-0">
                      <v-color-picker :value="border_settings.all.color"
                                      @update:color.capture="onBorderColorChange($event, 'all')"
                                      class="dont-close"
                                      flat mode="hexa"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>

            </div>
            <div class="border-settings-row">
              <div class="border-settings-icon"
                   :class="border_settings.around.on ? 'grey' : 'grey lighten-2'"
              >
                <v-icon size="20" style="flex: 0 0 30px">
                  mdi-border-all-variant
                </v-icon>
              </div>

              <div class="border-style-div">
                <div @click.stop="onBorderStyleClick('around')" class="border-style-line">
                  <div :style="`border-bottom: ${border_settings.around.style}; height: 3px; width: 100%;`">
                  </div>
                </div>
                <div class="border-style-options elevation-2" v-click-outside="borderStyleDivClickOutside"
                     v-if="border_settings.around.show_border_style">
                  <div class="pa-2"
                       v-for="(item, idx) in border_style_options" :key="idx"
                       @click="onBorderStyleElementClick('around', item)"
                  >
                    <div style="height: 3px"></div>
                    <div :style="item"></div>
                    <div style="height: 3px"></div>
                  </div>
                </div>
              </div>

              <div class="color-square-wrapper">
                <v-menu v-model="border_settings.around.show_border_color" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div
                        class="color-square"
                        :style="`background-color: ${border_settings.around.color}; pointer-events: ${border_settings.around.on ? 'all' : 'none'}`"
                        :class="{'color-picker-open': border_settings.around.show_border_color, 'color-picker-close': !border_settings.around.show_border_color}" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker :value="border_settings.all.color"
                                      @update:color.capture="onBorderColorChange($event, 'around')"
                                      class="dont-close"
                                      flat mode="hexa"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>

            </div>
            <div class="border-settings-row">
              <div class="border-settings-icon"
                   :class="border_settings.top.on ? 'grey' : 'grey lighten-2'"
              >
                <v-icon size="20" style="flex: 0 0 30px">
                  mdi-border-top
                </v-icon>
              </div>

              <div class="border-style-div">
                <div @click.stop="onBorderStyleClick('top')" class="border-style-line">
                  <div :style="`border-bottom: ${border_settings.top.style}; height: 3px; width: 100%;`">
                  </div>
                </div>
                <div class="border-style-options elevation-2" v-click-outside="borderStyleDivClickOutside"
                     v-if="border_settings.top.show_border_style">
                  <div class="pa-2"
                       v-for="(item, idx) in border_style_options" :key="idx"
                       @click="onBorderStyleElementClick('top', item)"
                  >
                    <div style="height: 3px"></div>
                    <div :style="item"></div>
                    <div style="height: 3px"></div>
                  </div>
                </div>
              </div>

              <div class="color-square-wrapper">
                <v-menu v-model="border_settings.top.show_border_color" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div
                        class="color-square"
                        :style="`background-color: ${border_settings.top.color};pointer-events: ${border_settings.top.on ? 'all' : 'none'}`"
                        :class="{'color-picker-open': border_settings.top.show_border_color, 'color-picker-close': !border_settings.top.show_border_color}" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker :value="border_settings.top.color"
                                      @update:color.capture="onBorderColorChange($event, 'top')"
                                      class="dont-close"
                                      flat mode="hexa"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>

            </div>
            <div class="border-settings-row">
              <div class="border-settings-icon"
                   :class="border_settings.right.on ? 'grey' : 'grey lighten-2'"
              >
                <v-icon size="20" style="flex: 0 0 30px">
                  mdi-border-right
                </v-icon>
              </div>

              <div class="border-style-div">
                <div @click.stop="onBorderStyleClick('right')" class="border-style-line">
                  <div :style="`border-bottom: ${border_settings.right.style}; height: 3px; width: 100%;`">
                  </div>
                </div>
                <div class="border-style-options elevation-2" v-click-outside="borderStyleDivClickOutside"
                           v-if="border_settings.right.show_border_style">
                <div class="pa-2"
                     v-for="(item, idx) in border_style_options" :key="idx"
                     @click="onBorderStyleElementClick('right', item)"
                >
                  <div style="height: 3px"></div>
                  <div :style="item"></div>
                  <div style="height: 3px"></div>
                </div>
              </div>
              </div>

              <div class="color-square-wrapper">
                <v-menu v-model="border_settings.right.show_border_color" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div
                        class="color-square"
                        :style="`background-color: ${border_settings.right.color};pointer-events: ${border_settings.right.on ? 'all' : 'none'}`"
                        :class="{'color-picker-open': border_settings.right.show_border_color, 'color-picker-close': !border_settings.right.show_border_color}" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker :value="border_settings.right.color"
                                      @update:color.capture="onBorderColorChange($event, 'right')"
                                      class="dont-close"
                                      flat mode="hexa"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>

            </div>
            <div class="border-settings-row">
              <div class="border-settings-icon"
                   :class="border_settings.bottom.on ? 'grey' : 'grey lighten-2'"
              >
                <v-icon size="20" style="flex: 0 0 30px">
                  mdi-border-bottom
                </v-icon>
              </div>

              <div class="border-style-div">
                <div @click.stop="onBorderStyleClick('bottom')" class="border-style-line">
                  <div :style="`border-bottom: ${border_settings.bottom.style}; height: 3px; width: 100%;`">
                  </div>
                </div>
                <div class="border-style-options elevation-2" v-click-outside="borderStyleDivClickOutside"
                           v-if="border_settings.bottom.show_border_style">
                <div class="pa-2"
                     v-for="(item, idx) in border_style_options" :key="idx"
                     @click="onBorderStyleElementClick('bottom', item)"
                >
                  <div style="height: 3px"></div>
                  <div :style="item"></div>
                  <div style="height: 3px"></div>
                </div>
              </div>
              </div>

              <div class="color-square-wrapper">
                <v-menu v-model="border_settings.bottom.show_border_color" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div
                        class="color-square"
                        :style="`background-color: ${border_settings.bottom.color};pointer-events: ${border_settings.bottom.on ? 'all' : 'none'}`"
                        :class="{'color-picker-open': border_settings.bottom.show_border_color, 'color-picker-close': !border_settings.bottom.show_border_color}" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker :value="border_settings.bottom.color"
                                      @update:color.capture="onBorderColorChange($event, 'bottom')"
                                      class="dont-close"
                                      flat mode="hexa"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>

            </div>
            <div class="border-settings-row">
              <div class="border-settings-icon"
                   :class="border_settings.left.on ? 'grey' : 'grey lighten-2'"
              >
                <v-icon size="20" style="flex: 0 0 30px">
                  mdi-border-left
                </v-icon>
              </div>

              <div class="border-style-div">
                <div @click.stop="onBorderStyleClick('left')" class="border-style-line">
                  <div :style="`border-bottom: ${border_settings.left.style}; height: 3px; width: 100%;`">
                  </div>
                </div>
                <div class="border-style-options elevation-2" v-click-outside="borderStyleDivClickOutside"
                           v-if="border_settings.left.show_border_style">
                <div class="pa-2"
                     v-for="(item, idx) in border_style_options" :key="idx"
                     @click="onBorderStyleElementClick('left', item)"
                >
                  <div style="height: 3px"></div>
                  <div :style="item"></div>
                  <div style="height: 3px"></div>
                </div>
              </div>
              </div>

              <div class="color-square-wrapper">
                <v-menu v-model="border_settings.left.show_border_color" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div
                        class="color-square"
                        :style="`background-color: ${border_settings.left.color};pointer-events: ${border_settings.left.on ? 'all' : 'none'}`"
                        :class="{'color-picker-open': border_settings.left.show_border_color, 'color-picker-close': !border_settings.left.show_border_color}" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker :value="border_settings.left.color"
                                      @update:color.capture="onBorderColorChange($event, 'left')"
                                      class="dont-close"
                                      flat mode="hexa"
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>

            </div>
          </div>
        </v-btn>
        <v-btn small depressed
               class="pa-0 default-font-button"
               :color="font_settings.word_wrap ? 'grey lighten-1' : 'grey lighten-3'"
               @click.stop="applyWordWrapStyle"
        >
          <v-icon size="18">mdi-format-text-wrapping-wrap</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button"
               style="margin-left: 6px"
               color="grey lighten-3"
               :disabled="!history_arr_length || current_history_index === 0"
               @click.stop="setCurrentHistoryIndexLeft"
        >
          <v-icon size="18">mdi-arrow-u-left-bottom-bold</v-icon>
        </v-btn>
        <v-btn small depressed class="pa-0 default-font-button"
               style="margin-left: 6px"
               color="grey lighten-3"
               :disabled="!history_arr_length || current_history_index === -1"
               @click.stop="setCurrentHistoryIndexRight"
        >
          <v-icon size="18">mdi-arrow-u-right-bottom-bold</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small depressed class="pa-0 default-form-button mr-2" width="120" @click="setting_dialog = true">
          <v-icon color="success" left size="17">
            mdi-cog-outline
          </v-icon>
          Пареметри
        </v-btn>
        <v-btn small depressed class="pa-0 default-form-button" @click="closeDialog">
          <v-icon color="error" left size="17">
            mdi-close
          </v-icon>
          Закрити
        </v-btn>
      </div>
    </div>
    <div style="overflow:auto;" :style="`height: calc(100vh - 40px)`">
      <table id='sprTable'
             :style="`width: ${appWidth}px;`"
             @mousedown="onStartDrag"
             @mouseup="onStopDrag"
             @mousemove="onDrag"
             style="border-collapse: collapse; border-spacing: 0; table-layout: fixed;">
        <colgroup id="sprTableColGroup"></colgroup>
        <tbody id="sprTableTBody" :style="`width: ${appWidth}px;`">

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ModalComponentMixin from "@/mixins/modal_component";
import {debounce, getCurrentDate} from "@/utils/icons";
import {QUESTION_SHOW} from "@/store/actions/question";
import spreedSheetAPI from "@/utils/axios/spreed_sheet"
import {ALERT_SHOW} from "@/store/actions/alert";
import {CREATE_SPREED_SHEET, UPDATE_SPREED_SHEET, DELETE_SPREED_SHEET} from "@/store/actions/spreed_sheet";
import {formatDate} from "@/filters";

const modalDeleteId = 'spreed_sheet_viewer_modal_delete'

export default {
  name: "HWP_Modal_SpreedSheetViewer",
  mixins: [ModalComponentMixin],
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '90%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '70%'
      }

      return '60%'
    },
    current_field_select_list() {
      if (this.field_selection.show_field_menu) {
        if (this.field_selection.current_group) {
          return this.field_selection.fields.filter(i => i.group ===this.field_selection.current_group)
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  data() {
    return {
      element_name: this.item.name || '',
      element_page_size: this.item.page_size || 'letter',
      element_items_by_page: this.item.items_by_page || 0,
      element_show_in_flat: this.item.show_in_flat || false,
      element_string_json: '',
      element_config: {},
      appWidth: 0,
      appHeight: 0,
      start_col_move: false,
      start_row_move: false,
      resize_col_start_position: 0,
      resize_col_start_width: 0,
      resize_col_index: null,
      resize_row_start_position: 0,
      resize_row_start_height: 0,
      resize_row_index: 0,
      last_active_cell: null,
      selection: {
        row_start: null,
        row_end: null,
        col_start: null,
        col_end: null,
        start: false,
        last_known_cell: null
      },
      x: 0,
      y: 0,
      x_field: 100,
      y_filed: 50,
      x_format: 0,
      y_format: 0,
      x_empty: 0,
      y_empty: 0,
      x_additional: 0,
      y_additional: 0,
      x_image: 0,
      y_image: 0,
      show_context_menu: false,
      font_settings: {
        bold: false,
        italic: false,
        underline: false,
        word_wrap: false,
        size: 13,
        family: 'Verdana',
        show_font_options: false,
        show_font_family_options: false,
        vertical_align: 'top',
        horizontal_align: 'left',
        color: '#000000',
        background_color: '#ffffff',
        show_font_color: false,
        show_background_color: false
      },
      border_settings: {
        show: false,
        all: {on: false, style: null, color: '#000000', show_border_color: false, show_border_style: false},
        around: {on: false, style: null, color: '#000000', show_border_color: false, show_border_style: false},
        top: {on: false, style: null, color: '#000000', show_border_color: false, show_border_style: false},
        right: {on: false, style: null, color: '#000000', show_border_color: false, show_border_style: false},
        bottom: {on: false, style: null, color: '#000000', show_border_color: false, show_border_style: false},
        left: {on: false, style: null, color: '#000000', show_border_color: false, show_border_style: false},
      },
      fonts_option: [
        'Arial',
        'Calibri',
        'Courier',
        'Times New Roman',
        'Verdana'
      ],
      font_size_options: [
          6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32
      ],
      border_style_options: [
          'border-bottom: none',
          'border-bottom: 1px solid #000000',
          'border-bottom: 2px solid #000000',
          'border-bottom: 1px dashed #000000; height: 4px',
          'border-bottom: 1px dotted #000000; height: 6px',
          'border-bottom: 3px solid #000000',
          'border-bottom: 3px double #000000',
          'border-bottom: 1px solid #000000',
          'border-bottom: 2px dashed #000000',
      ],
      current_border_element: null,
      current_input_id: null,
      general_rows: 100,
      last_edit_row: 0,
      last_edit_col: 0,
      setting_dialog: false,
      parameters_table: [],
      image_table: [],
      field_select: [],
      current_history_index: -1,
      debounceFn: null,
      history_max_length: 15,
      history_arr_length: 0,
      field_selection: {
        group: [
          { text: 'Дані організації', value: 'organization', icon: 'mdi-domain' },
          { text: 'Дані абонента', value: 'flat', icon: 'mdi-home-city'  },
          { text: 'Дані власника', value: 'owner', icon: 'mdi-account-tie'  },
          { text: 'Дані договору', value: 'contract', icon: 'mdi-invoice-list-outline'  },
          { text: 'Дані заборгованості', value: 'balance', icon: 'mdi-circle-multiple-outline'  },
          { text: 'Дані відповідальних осіб', value: 'response_person', icon: 'mdi-circle-multiple-outline'  },
          { text: 'Системні', value: 'system', icon: 'mdi-cog'  },
        ],
        fields: [
          { text: 'Коротка назва організації', value: 'organization_short_name', group: 'organization' },
          { text: 'Повна назва організації', value: 'organization_full_name', group: 'organization' },
          { text: 'Код ЄДРПОУ', value: 'organization_code', group: 'organization' },
          { text: 'Ідентифікаційний код', value: 'organization_ipn', group: 'organization' },
          { text: 'Юридична адреса', value: 'organization_address_yur', group: 'organization' },
          { text: 'Фактична адреса', value: 'organization_address_fact', group: 'organization' },
          { text: 'Реквізити (платіжні)', value: 'organization_bank_account_data', group: 'organization' },
          { text: 'Реквізити (повні)', value: 'organization_details_with_account', group: 'organization' },
          { text: 'Реквізити (повні без контактів)', value: 'organization_details_with_account_without_contacts', group: 'organization' },
          { text: 'Реквізити (без платіжних)', value: 'organization_details_without_account', group: 'organization' },
          { text: 'Банк', value: 'organization_bank', group: 'organization' },
          { text: 'Повна адреса', value: 'flat_full_address', group: 'flat' },
          { text: 'Коротка адреса', value: 'flat_short_address', group: 'flat' },
          { text: 'Громада', value: 'flat_amalgamated', group: 'flat' },
          { text: 'Населений пункт', value: 'flat_city_name', group: 'flat' },
          { text: 'Район міста', value: 'flat_city_area_name', group: 'flat' },
          { text: 'Вулиця', value: 'flat_street_name', group: 'flat' },
          { text: 'Будинок', value: 'flat_building_name', group: 'flat' },
          { text: 'Тип будинку', value: 'flat_building_type_name', group: 'flat' },
          { text: 'Номер будинку', value: 'flat_building_number', group: 'flat' },
          { text: 'Номер будинку (число)', value: 'flat_building_number_int', group: 'flat' },
          { text: 'Номер будинку (рядок)', value: 'flat_building_number_string', group: 'flat' },
          { text: 'Номер квартири', value: 'flat_flat_number', group: 'flat' },
          { text: 'Номер квартири (число)', value: 'flat_flat_number_int', group: 'flat' },
          { text: 'Номер квартири (рядок)', value: 'flat_flat_number_string', group: 'flat' },
          { text: 'Поштовий індекс', value: 'flat_postal_index', group: 'flat' },
          { text: 'Особовий рахунок', value: 'flat_person_id', group: 'flat' },
          { text: 'Нормативний параметр', value: 'flat_indicator_value', group: 'flat' },
          { text: 'Тариф', value: 'flat_tariff', group: 'flat' },
          { text: 'ПІБ', value: 'flat_owner_full_name', group: 'owner' },
          { text: 'ПІБ (скорочено)', value: 'flat_owner_short_name', group: 'owner' },
          { text: 'Прізвище', value: 'flat_owner_first_name', group: 'owner' },
          { text: 'Ім’я', value: 'flat_owner_middle_name', group: 'owner' },
          { text: 'По батькові', value: 'flat_owner_last_name', group: 'owner' },
          { text: 'Ідентифікаційний код', value: 'flat_owner_ipn', group: 'owner' },
          { text: 'Паспорт', value: 'flat_owner_passport', group: 'owner' },
          { text: 'Телефон', value: 'flat_owner_phone_number', group: 'owner' },
          { text: 'Email', value: 'flat_owner_email', group: 'owner' },
          { text: 'Дата', value: 'contract_date', group: 'contract' },
          { text: 'Номер', value: 'contract_number', group: 'contract' },
          { text: 'Сальдо на початок', value: 'balance_balance_start', group: 'balance' },
          { text: 'Сальдо на початок за "-" оплати', value: 'balance_balance_start_exclude_pay', group: 'balance' },
          { text: 'Нараховано', value: 'balance_charge', group: 'balance' },
          { text: 'Знято', value: 'balance_removal', group: 'balance' },
          { text: 'Перераховано', value: 'balance_recalculation', group: 'balance' },
          { text: 'Оплачено', value: 'balance_pay', group: 'balance' },
          { text: 'Сальдо на кінець', value: 'balance_balance_end', group: 'balance' },
          { text: 'Директор ПІБ', value: 'organization_director_full_name', group: 'response_person' },
          { text: 'Директор ініціали', value: 'organization_director_short_name', group: 'response_person' },
          { text: 'Директор посада', value: 'organization_director_position', group: 'response_person' },
          { text: 'Директор ІПН', value: 'organization_director_ipn', group: 'response_person' },
          { text: 'Гол. бухгалтер ПІБ', value: 'organization_chief_accounting_full_name', group: 'response_person' },
          { text: 'Гол. бухгалтер ініціали', value: 'organization_chief_accounting_short_name', group: 'response_person' },
          { text: 'Гол. бухгалтер посада', value: 'organization_chief_accounting_position', group: 'response_person' },
          { text: 'Гол. бухгалтер ІПН', value: 'organization_chief_accounting_ipn', group: 'response_person' },
          { text: 'Поточна дата', value: 'system_current_date', group: 'system' },
          { text: 'Поточний місяць', value: 'system_current_month', group: 'system' },
          { text: 'Дата звіту з', value: 'system_report_date_start', group: 'system' },
          { text: 'Дата звіту по', value: 'system_report_date_end', group: 'system' },
        ],
        current_group: null,
        show_field_menu: false,
        current_field_value: null,
        current_field_text: null,
        row_num: null
      },
      format_selection: {
        show_field_menu: false,
        current_field_value: null,
        row_num: null,
        number_format_precision: 0,
        date_format_value: null,
        date_format_text: null,
        boolean_true_value: null,
        boolean_false_value: null,
        date_formats: [
            '%a, %A - назва дня тижня',
            '%w - номер дня тижня',
            '%d - день місяця у форматі 01, 02 ... 31',
            '%b, %B - назва місяця',
            '%m - місяць у форматі 01, 02 ... 12',
            '%y, %Y - рік',
            '%j - номер дня у році',
        ]
      },
      empty_selection: {
        show_field_menu: false,
        current_field_value: null,
        row_num: null,
        empty_value: null
      },
      additional_selection: {
        show_field_menu: false,
        current_field_value: null,
        selected_field: null,
        row_num: null,
        fields: [
          { text: 'початок_місяця', value: 'start_of_month(<field|current_field>)' },
          { text: 'кінець_місяця', value: 'end_of_month(<field|current_field>)' },
          { text: 'додати_день', value: 'add_days(<field|current_field>, <days>)' },
          { text: 'додати_місяць', value: 'add_months(<field|current_field>, <months>)' },
          { text: 'умова', value: 'condition(<field|current_field>[=,>,<]value>, <text>)' },
          { text: 'формула', value: 'formula[<field|current_field|float>[+,-,*,/]<field|current_field|float>' },
          { text: 'видалити_пробіли', value: 'trim()' },
        ]
      },
      image_data: {
        show_context_menu: false,
        type: '',
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        url: '',
        selected_image: '',
        type_select: [
          { text: 'QR організації', value: 'qr_organization' },
          { text: 'QR абонента', value: 'qr_account' },
          { text: 'Довільна картинка', value: 'custom_image' },
        ],
        images_select: []
      },
      pageSizeTypes: [
        { text: 'Портретна', value: 'letter' },
        { text: 'Альбомна', value: 'landscape' },
      ],
      publicPath: process.env.BASE_URL,
      file_qr: null
    }
  },
  methods: {
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.element_name = this.item.name || ''
      this.element_page_size = this.item.page_size || 'letter'
      this.element_items_by_page = this.item.items_by_page || 0
      this.element_show_in_flat = this.item.show_in_flat || false
      this.element_string_json = ''
      this.element_config = {}
    },
    closeDialog() {
      const payload = {
        text: `Підтвердіть закриття цієї друкованої форми. Зміни збережені не будуть.`,
        accept_button: true,
        id: 'close-dialog-accept'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення цієї друкованої форми`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.element_name = payload.name || ''
              this.element_page_size = payload.page_size || ''
              this.element_items_by_page = payload.items_by_page || 0
              this.element_show_in_flat = payload.show_in_flat || false
              this.element_string_json = ''
              this.element_config = {}

              this.parameters_table = []
              this.selection.row_start = 0
              this.selection.row_end = 0
              this.selection.col_start = 0
              this.selection.col_end = 0
              this.selection.start = false
              this.selection.last_known_cell = null

              this.$nextTick(() => {
                if (this.isNew) {
                  this.createEmptyTable()
                } else {
                  this.createEmptyTable()
                  this.get_spreed_sheet_config()
                }
              })
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_SPREED_SHEET, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal({fetch: true, emit: true})
                        }
                      })
                }
              }
              if (payload.id === 'close-dialog-accept') {
                if (payload.answer) {
                  this.closeModal()
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
                this.clearTable()
              }
            }
          }
      )
    },
    crudItem() {
      const payload_json = this.buildHistory(true)
      payload_json.params = JSON.parse(JSON.stringify(this.parameters_table))
      payload_json.images = JSON.parse(JSON.stringify(this.image_table))
      delete payload_json.selection

      const payload_json_text = JSON.stringify(payload_json)

      const payload = {
        name: this.element_name,
        page_size: this.element_page_size,
        items_by_page: this.element_items_by_page,
        show_in_flat: this.element_show_in_flat,
        config: payload_json_text
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_SPREED_SHEET, payload)
            .then(el => {
              if (el) {
                this.closeModal({fetch: true, emit: true})
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_SPREED_SHEET, payload)
            .then(el => {
              if (el) {
                this.closeModal({fetch: true, emit: true})
              }
            })
      }

    },
    get_spreed_sheet_config() {
      if (this.isNew) return
      spreedSheetAPI.get_config(this.itemId)
          .then(response => response.data)
          .then(data => {
            const config_json = JSON.parse(data)
            const history_row = {
              last_edit_row: config_json.last_edit_row,
              last_edit_col: config_json.last_edit_col,
              table: config_json.table,
              col_group: config_json.col_group,
              selection: {
                col_start: 1,
                col_end: 1,
                row_start: 1,
                row_end: 1
              }
            }
            this.parameters_table = config_json.params
            this.image_table = config_json.images || []

            this.restoreHistoryRow(history_row, true)
            this.setPageSizeLimitOnA4()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },

    uploadImage() {
      if (this.file_qr) {
        this.create_image_on_server()
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Спершу оберіть картинку', color: 'error'})
      }
    },
    onImageSelect(payload) {
      const local_payload = payload || -1
      if (local_payload) {
        const find = this.image_data.images_select.find(i => i.value === local_payload)
        if (find) {
          this.image_data.url = find.type || ''
        } else {
          this.image_data.url = ''
        }
      } else {
        this.image_data.url = ''
      }
    },
    create_image_on_server() {
      if (!this.file_qr) {
        return
      }

      const qr_payload = new FormData()
      qr_payload.append('file', this.file_qr)

      spreedSheetAPI.create_image(qr_payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              spreedSheetAPI.select_images()
                  .then(response => response.data)
                  .then(data => {
                    this.image_data.images_select = data
                  })

              this.$store.commit(ALERT_SHOW, {text: 'Картинка успішно завантажена', color: 'success'})
            } else {
              this.$store.commit(ALERT_SHOW, {text: 'Не вдалось завантажити картинку', color: 'success'})
            }
          })
          .finally(() => {
            this.file_qr = null
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },

    buildNumberFormat() {
      const current_format = `{N,${this.format_selection.number_format_precision}}`
      if (!this.format_selection.current_field_value) {
        this.format_selection.current_field_value += current_format
      } else {
        this.format_selection.current_field_value += ` ${current_format}`
      }
    },
    buildDateFormat() {
      const current_format = `{D,${this.format_selection.date_format_value}}`
      if (!this.format_selection.current_field_value) {
        this.format_selection.current_field_value += current_format
      } else {
        this.format_selection.current_field_value += ` ${current_format}`
      }
    },
    buildBooleanFormat() {
      const current_format = `{B,'${this.format_selection.boolean_true_value}', '${this.format_selection.boolean_false_value}'}`
      if (!this.format_selection.current_field_value) {
        this.format_selection.current_field_value += current_format
      } else {
        this.format_selection.current_field_value += ` ${current_format}`
      }
    },
    buildEmptyFormat() {
      const current_format = `{N,${this.empty_selection.empty_value}}`
      if (!this.empty_selection.current_field_value) {
        this.empty_selection.current_field_value += current_format
      } else {
        this.empty_selection.current_field_value += ` ${current_format}`
      }
    },
    buildImage() {
      const letter_cols = this.genColNames()
      let min = this.getMin()
      const td_id = `row-${min.row};cell-int-${min.col};cell-letter-${letter_cols[min.col]}`
      const el = document.getElementById(td_id)

      if (el) {
        el.setAttribute('image-type', this.image_data.type)
        el.setAttribute('image-url', this.image_data.url)
        el.setAttribute('image-position-left', `${this.image_data.left}`)
        el.setAttribute('image-position-top', `${this.image_data.top}`)
        el.setAttribute('image-height', `${this.image_data.height}`)
        el.setAttribute('image-width', `${this.image_data.width}`)
        el.setAttribute('image-url', `${this.image_data.url}`)

        let image = null
        if (el.childNodes.length === 2) {
          image = el.childNodes[1].childNodes[0]
        } else {
          image = el.childNodes[0].childNodes[0]
        }
        image.style.width = `${this.image_data.width}px`
        image.style.height = `${this.image_data.height}px`
        image.style.top = `${this.image_data.top}${this.image_data.top > 0 ? 'px' : ''}`
        image.style.left = `${this.image_data.left}${this.image_data.left > 0 ? 'px' : ''}`

        this.toggleQrInTable(el.id, this.image_data.type, true, this.image_data.url)
      }
      this.image_data.show_context_menu = false
    },
    deleteImage() {
      const letter_cols = this.genColNames()
      let min = this.getMin()
      const td_id = `row-${min.row};cell-int-${min.col};cell-letter-${letter_cols[min.col]}`
      const el = document.getElementById(td_id)

      if (el) {
        el.textContent = ''
        this.toggleQrInTable(el.id, '', false)
        el.removeAttribute('image-type')
        el.removeAttribute('image-height')
        el.removeAttribute('image-width')
        el.removeAttribute('image-position-left')
        el.removeAttribute('image-position-top')
        el.removeAttribute('image-url')
        el.classList.remove('image-box')

        this.image_data.top = 0
        this.image_data.left = 0
        this.image_data.height = 0
        this.image_data.width = 0
        this.image_data.type = ''
        this.image_data.url = ''
        this.image_data.selected_image = null
        this.image_data.show_context_menu = false

        this.setCellHeight(el)
      }
    },
    addFieldToAdditional(payload) {
      const id = `additional-textarea-${this.additional_selection.row_num}`
      const textarea = document.getElementById(id)
      let new_value = ''
      if (!payload) return

      if (textarea) {
        const position = textarea.selectionStart
        const before = textarea.value.substring(0, position)
        const after = textarea.value.substring(position, textarea.value.length)
        new_value = `${before}${payload}${after}`

        this.$nextTick(() => {
          this.additional_selection.current_field_value = new_value
          textarea.selectionStart = textarea.selectionEnd = position + payload.length;
          this.additional_selection.selected_field = null
        })
      }
    },
    addFunctionToAdditional(text) {
      const id = `additional-textarea-${this.additional_selection.row_num}`
      const textarea = document.getElementById(id)
      let new_value = ''

      if (textarea) {
        const position = textarea.selectionStart
        const before = textarea.value.substring(0, position)
        const after = textarea.value.substring(position, textarea.value.length)
        if (!before && !after) {
          new_value = `{${text};}`
        } else {
          new_value = `${before}${text};${after}`
        }

        this.$nextTick(() => {
          this.additional_selection.current_field_value = new_value
          textarea.selectionStart = textarea.selectionEnd = position + text.length;
        })
      }
    },
    parseFormatForMoment(payload) {
      const for_replace = {
        '%a': 'ddd',
        '%A': 'dddd',
        '%d': 'DD',
        '%b': 'MMM',
        '%B': 'MMMM',
        '%m': 'MM',
        '%y': 'YY',
        '%Y': 'YYYY',
        '%j': 'DDDD',
        '%w': 'd'
      }

      let js_format = "" + (payload || '')
      const regex = /%./gm
      const arr = [...js_format.matchAll(regex)]
      arr.forEach(i => {
        const replace_word = i[0]
        js_format = js_format.replace(replace_word, for_replace[replace_word] || replace_word)
      })

      if (js_format) {
        const current_date = getCurrentDate()
        this.format_selection.date_format_text = formatDate(current_date, js_format)
      }
    },

    clearTable() {
      const colgroup = document.getElementById('sprTableColGroup')
      const tbody = document.getElementById('sprTableTBody')

      while (colgroup.firstChild) {
        colgroup.removeChild(colgroup.firstChild);
      }

      while (tbody.firstChild) {
        tbody.removeChild(tbody.firstChild);
      }
    },
    fillParameters() {
      const letters = this.genColNames()
      const parameters_table = []
      let global_row_number = 0

      for (let row = 1; row < this.last_edit_row; row++) {
        for (let col = 1; col < this.last_edit_col; col++) {
          const td_id = `row-${row};cell-int-${col};cell-letter-${letters[col]}`

          const el = document.getElementById(td_id)
          const reg = /\[[^[ \]]+]/gm

          if (el) {
            const text_content = el.textContent

            if (text_content) {
              const arr = [...text_content.matchAll(reg)]
              const param_arr = []

              arr.forEach(i => {

                const replace_word = i[0]
                if (!param_arr.includes(replace_word)) {
                  param_arr.push(replace_word)
                  global_row_number += 1

                  parameters_table.push(
                      {
                        address: td_id,
                        short_address: `${letters[col]}${row}`,
                        parameter: replace_word,
                        value: null,
                        format: null,
                        empty: null,
                        additional: null,
                        row_num: global_row_number
                      }
                  )
                }
              })
            }
          }
        }
      }

      parameters_table.forEach(item => {
        const row = this.parameters_table.find(i => i.address === item.address)
        if (row) {
          item.value = row.value
          item.format = row.format
          item.additional = row.additional
          item.empty = row.empty
        }
      })
      this.parameters_table = parameters_table
    },
    closeSettings() {
      this.setting_dialog = false
    },
    onResize(e) {
      this.appWidth = e.target.innerWidth
      this.appHeight = e.target.innerHeight
    },
    applyEditColAndRow(row, col, add_row=1, add_col=1, minus_row=false, minus_col=false) {
      const cols = this.genColNames()

      this.clearLastEditRowAndCol()

      this.last_edit_col = Math.max(this.last_edit_col + (minus_row ? -1 : 0), col + add_col)
      this.last_edit_row = Math.max(this.last_edit_row + (minus_col ? -1 : 0), row + add_row)

      for (let c = 1; c <= this.last_edit_col - 1; c++) {
        const col_el = document.getElementById(`row-${this.last_edit_row};cell-int-${c};cell-letter-${cols[c]}`)
        if (col_el) {
          col_el.classList.add('last-edit-row')
        }
      }

      for (let r = 1; r <= this.last_edit_row - 1; r++) {
        const col_el = document.getElementById(`row-${r};cell-int-${this.last_edit_col};cell-letter-${cols[this.last_edit_col]}`)
        if (col_el) {
          col_el.classList.add('last-edit-col')
        }
      }

      this.doBuildHistory()
    },
    include () {
      return [document.querySelector('.v-color-picker')]
    },
    onClickOutside(element, callback) {
      document.addEventListener('click', e => {
        const color_picker = document.querySelector('.dont-close')
        if (!element.contains(e.target)) {
          if (color_picker) {
            if (!color_picker.contains(e.target)) {
              callback();
            }
          } else {
            callback();
          }
        }
      });
    },
    onTableKyeUp() {
      document.addEventListener('keyup', e => {
        if (e.key === 'Delete') {
          if (this.selection.row_start && this.selection.col_start) {
            const letter_cols = this.genColNames()

            let min = this.getMin(), max = this.getMax()

            for (let row = min.row; row < max.row + 1; row++) {
              for (let col = min.col; col < max.col + 1; col++) {

                const letter_col = letter_cols[col]
                const td_id = `row-${row};cell-int-${col};cell-letter-${letter_col}`

                const td_el = document.getElementById(td_id)

                if (td_el) {
                  td_el.textContent = ''
                  td_el.classList.remove('image-box')
                  if (td_el.getAttribute('image-type')) {
                    this.toggleQrInTable(td_el.id, '', false)
                    td_el.removeAttribute('image-type')
                    td_el.removeAttribute('image-height')
                    td_el.removeAttribute('image-width')
                    td_el.removeAttribute('image-position-left')
                    td_el.removeAttribute('image-position-top')
                    td_el.removeAttribute('image-url')
                    td_el.classList.remove('image-box')
                  }
                  this.setCellHeight(td_el)
                }
              }
            }

          }
        }
      })
    },
    onBorderStyleClick(payload) {
      this.border_settings[payload].show_border_style = true
      this.current_border_element = payload
    },
    borderStyleDivClickOutside() {
      if (!this.current_border_element) return
      this.border_settings[this.current_border_element].show_border_style = false
    },
    onBorderStyleElementClick(item, value) {
      let on = true
      const border = value.split(';')[0].split(':')[1].trim()

      if (border === 'none' || !border) {
        on = false
      }

      this.border_settings[item].show_border_style = false

      if (item === 'all') {
        this.border_settings.all.on = on
        this.border_settings.all.style = border
        this.border_settings.top.on = on
        this.border_settings.top.style = border
        this.border_settings.right.on = on
        this.border_settings.right.style = border
        this.border_settings.bottom.on = on
        this.border_settings.bottom.style = border
        this.border_settings.left.on = on
        this.border_settings.left.style = border
      } else {
        if (item === 'around') {
          this.border_settings.all.on = false
          this.border_settings.all.style = ''
          this.border_settings.around.on = on
          this.border_settings.around.style = border
          this.border_settings.top.on = on
          this.border_settings.top.style = border
          this.border_settings.right.on = on
          this.border_settings.right.style = border
          this.border_settings.bottom.on = on
          this.border_settings.bottom.style = border
          this.border_settings.left.on = on
          this.border_settings.left.style = border
        } else {
          this.border_settings.all.on = false
          this.border_settings.all.style = ''
          this.border_settings.around.on = false
          this.border_settings.around.style = ''
          this.border_settings[item].on = on
          this.border_settings[item].style = border
        }
      }

      this.borderStyleApply()
    },
    onFontFamilyClick(payload) {
      this.font_settings.family = payload
      this.applyStyle('font_settings', 'family', this.font_settings.family)
      this.font_settings.show_font_family_options = false
    },
    rgbToHex(a){
      if (a[0] === '#') return a
      a=a.replace(/[^\d,]/g,"").split(",");
      return"#"+((1<<24)+(+a[0]<<16)+(+a[1]<<8)+ +a[2]).toString(16).slice(1)
    },
    onBorderColorChange(payload, item) {
      const s = this.border_settings[item].style.split(' ')
      const new_style = `${s[0]} ${s[1]} ${payload.hex.toLowerCase()}`
      this.border_settings[item].color = payload.hex.toLowerCase()
      this.border_settings[item].style = new_style

      this.borderStyleApply()
    },
    onTextColorChange(payload) {
      if (this.font_settings.color === payload.hex.toLowerCase()) return
      this.font_settings.color = payload.hex.toLowerCase()
      this.applyStyle('font_settings', 'color', this.font_settings.color)
    },
    onBackgroundColorChange(payload) {
      if (this.font_settings.background_color === payload.hex.toLowerCase()) return
      this.font_settings.background_color = payload.hex.toLowerCase()
      this.applyStyle(
          'font_settings',
          'background_color',
          this.font_settings.background_color === '#ffffff' ? null : this.font_settings.background_color)
    },
    setFontSize(payload) {
      this.font_settings.size = payload
      this.applyStyle('font_settings', 'size', this.font_settings.size)
      this.font_settings.show_font_options = false
    },
    onFontSizeChange(payload) {
      this.font_settings.size = parseInt(payload.target.value)
      this.applyStyle('font_settings', 'size', this.font_settings.size)
      this.font_settings.show_font_options = false
    },
    camelCase(str) {
      const str1 = str.replace('-', ' ')
      return str1.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    transformBorderStyleToObject(payload) {
      let border = payload ? payload.trim() : ''
      border = border.replaceAll(', ', ',')
      const width = border.split(' ')[0]
      const style = border.split(' ')[1]
      let color = border.split(' ')[2] || '#000000'
      color = this.rgbToHex(color)

      return {
        css_style: border ? `${width} ${style} ${color}` : '',
        css_color: color ? color : '#000000',
        css_on: !!border
      }
    },
    convertStyleToSettings(style) {
      if (!style) return
      const vertical_align = {
        center: 'vcenter',
        top: 'top',
        bottom: 'bottom',
        middle: 'vcenter'
      }
      const fontFamily = style.fontFamily
      this.font_settings.bold = style.fontWeight === 'bold'
      this.font_settings.italic = style.fontStyle === 'italic'
      this.font_settings.underline = style.textDecoration === 'underline'
      this.font_settings.size = style.fontSize ? parseInt(style.fontSize.replace('px', '')) : 13
      this.font_settings.family = fontFamily ? fontFamily : 'Verdana'
      this.font_settings.vertical_align = style.verticalAlign ? vertical_align[style.verticalAlign] : 'vcenter'
      this.font_settings.horizontal_align = style.textAlign ? style.textAlign : 'left'
      this.font_settings.color = style.color ? this.rgbToHex(style.color) : '#000000'
      this.font_settings.background_color = style.backgroundColor ? this.rgbToHex(style.backgroundColor) : '#ffffff'
      this.font_settings.word_wrap = style.whiteSpace === 'normal'

      const ab = this.transformBorderStyleToObject(style.border)
      const tb = this.transformBorderStyleToObject(style.borderTop)
      const rb = this.transformBorderStyleToObject(style.borderRight)
      const bb = this.transformBorderStyleToObject(style.borderBottom)
      const lb = this.transformBorderStyleToObject(style.borderLeft)

      this.border_settings.around.on = false
      this.border_settings.around.style = ''
      this.border_settings.around.color = "#000000"

      this.border_settings.all.on = ab.css_on
      this.border_settings.all.style = ab.css_style
      this.border_settings.all.color = ab.css_color

      if (!this.border_settings.all.on && tb.css_on && rb.css_on && bb.css_on && lb.css_on) {
        this.border_settings.around.on = true
        this.border_settings.around.style = tb.css_style
        this.border_settings.around.color = tb.css_color
      }

      this.border_settings.top.on = tb.css_on
      this.border_settings.top.style = tb.css_style
      this.border_settings.top.color = tb.css_color

      this.border_settings.right.on = rb.css_on
      this.border_settings.right.style = rb.css_style
      this.border_settings.right.color = rb.css_color

      this.border_settings.bottom.on = bb.css_on
      this.border_settings.bottom.style = bb.css_style
      this.border_settings.bottom.color = bb.css_color

      this.border_settings.left.on = lb.css_on
      this.border_settings.left.style = lb.css_style
      this.border_settings.left.color = lb.css_color

    },
    fontStyleApply(setting_key, setting_value) {
      const vertical_align = {
        vcenter: 'middle',
        top: 'top',
        bottom: 'bottom',
      }

      const settings = {
        bold: {key: 'font-weight', value: setting_value ? 'bold' : null},
        italic: {key: 'font-style', value: setting_value ? 'italic': null},
        underline: {key: 'text-decoration', value: setting_value ? 'underline': null},
        size: {key: 'font-size', value: `${setting_value ? `${setting_value}px` : ''}`},
        family: {key: 'font-family', value: `${setting_value}`},
        vertical_align: {key: 'vertical-align', value: vertical_align[setting_value]},
        horizontal_align: {key: 'text-align', value: setting_value},
        color: {key: 'color', value: setting_value},
        background_color: {key: 'background-color', value: setting_value},
        word_wrap: {key: 'white-space', value: setting_value ? 'pre-wrap' : null}
      }

      const row = settings[setting_key]

      return {
        key: this.camelCase(row.key),
        value: row.value ? row.value : ''
      }
    },
    borderStyleApply() {
      const letter_cols = this.genColNames()
      let min = this.getMin(), max = this.getMax()
      for (let row = min.row; row < max.row + 1; row++) {
        for (let col = min.col; col < max.col + 1; col++) {
          const letter_col = letter_cols[col]
          const td_id = `row-${row};cell-int-${col};cell-letter-${letter_col}`

          const td_el = document.getElementById(td_id)

          if (!td_el) continue

          td_el.style.border = ''
          td_el.style.borderTop = ''
          td_el.style.borderRight = ''
          td_el.style.borderBottom = ''
          td_el.style.borderLeft = ''

          if (this.border_settings.all.on) {
            td_el.style.border = this.border_settings.all.style
          } else {
            if (this.border_settings.around.on) {
              if (row === min.row) {
                td_el.style.borderTop = this.border_settings.top.style
              }
              if (col === max.col) {
                td_el.style.borderRight = this.border_settings.top.style
              }
              if (row === max.row) {
                td_el.style.borderBottom = this.border_settings.top.style
              }
              if (col === min.col) {
                td_el.style.borderLeft = this.border_settings.top.style
              }
            } else {
              if (this.border_settings.top.on) {
                if (row === min.row) {
                  td_el.style.borderTop = this.border_settings.top.style
                }
              }
              if (this.border_settings.right.on) {
                if (col === max.col) {
                  td_el.style.borderRight = this.border_settings.right.style
                }
              }
              if (this.border_settings.bottom.on) {
                if (row === max.row) {
                  td_el.style.borderBottom = this.border_settings.bottom.style
                }
              }
              if (this.border_settings.left.on) {
                if (col === min.col) {
                  td_el.style.borderLeft = this.border_settings.left.style
                }
              }
            }
          }
        }
      }
      this.applyEditColAndRow(max.row, max.col)
    },
    applyBoldStyle() {
      this.font_settings.bold = !this.font_settings.bold
      this.$nextTick(() => {
        this.applyStyle('font_settings', 'bold', this.font_settings.bold)
      })
    },
    applyItalicStyle() {
      this.font_settings.italic = !this.font_settings.italic
      this.$nextTick(() => {
        this.applyStyle('font_settings', 'italic', this.font_settings.italic)
      })
    },
    applyWordWrapStyle() {
      this.font_settings.word_wrap = !this.font_settings.word_wrap
      this.$nextTick(() => {
        this.applyStyle('font_settings', 'word_wrap', this.font_settings.word_wrap)
      })
    },
    applyUnderlineStyle() {
      this.font_settings.underline = !this.font_settings.underline
      this.$nextTick(() => {
        this.applyStyle('font_settings', 'underline', this.font_settings.underline)
      })
    },
    applyHorizontalLeft(value) {
      this.font_settings.horizontal_align = value
      this.$nextTick(() => {
        this.applyStyle('font_settings', 'horizontal_align', this.font_settings.horizontal_align)
      })
    },
    applyVerticalLeft(value) {
      this.font_settings.vertical_align = value
      this.$nextTick(() => {
        this.applyStyle('font_settings', 'vertical_align', this.font_settings.vertical_align)
      })
    },
    applyStyle(setting_name, setting_key, setting_value) {
      const letter_cols = this.genColNames()
      let min = this.getMin(), max = this.getMax()

      for (let row = min.row; row < max.row + 1; row++) {
        for (let col = min.col; col < max.col + 1; col++) {
          const letter_col = letter_cols[col]
          const td_id = `row-${row};cell-int-${col};cell-letter-${letter_col}`

          const td_el = document.getElementById(td_id)

          if (!td_el) continue

          if (setting_name === 'font_settings') {
            const style = this.fontStyleApply(setting_key, setting_value)
            td_el.style[style['key']] = style.value
            setTimeout(() => {
              this.setCellHeight(td_el)
            }, 200)
          }
        }
      }
      this.applyEditColAndRow(max.row, max.col)
    },
    onFontSizeClickOutside() {
      this.font_settings.show_font_options = false
    },
    onFontFamilyClickOutside() {
      this.font_settings.show_font_family_options = false
    },
    addColumnToLeft(left=0) {
      if (this.selection.row_start && this.selection.col_start) {
        const rows = this.general_rows
        const cols = this.genColNames()
        let min = this.getMin()

        const min_col = min.col + left

        const cols_splice = cols.slice(min_col)
        const splice_length = cols_splice.length

        const insert_before_arr = []
        const delete_arr = []
        const change_id_arr = []

        let cols_count = 0

        const col_for_check = left === 0 ? min_col - 1 : min_col + 1

        for (let row = 1; row <= rows; row++) {
          const prev_row_td_id = `row-${row === 1 ? row : row - 1};cell-int-${col_for_check};cell-letter-${cols[col_for_check]}`
          if (document.getElementById(prev_row_td_id)) {
            cols_count += 1
          }
        }

        for (let row = 1; row <= rows; row++) {
          cols_splice.forEach((col, idx) => {
            const real_idx = min_col + idx
            const real_td_id = `row-${row};cell-int-${real_idx};cell-letter-${col}`
            const next_td_id = `row-${row};cell-int-${real_idx + 1};cell-letter-${cols_splice[idx + 1]}`

            const el = document.getElementById(real_td_id)

            if (el) {
              if (real_idx === min_col) {
                const new_td = document.createElement('td')
                new_td.setAttribute('id', real_td_id)
                new_td.addEventListener('click', this.onCellClick)
                new_td.addEventListener('dblclick', this.onCellDoubleClick)
                new_td.addEventListener('contextmenu', this.onCellContextMenu)
                new_td.classList.add('cell')
                insert_before_arr.push(
                    {before: el, after: new_td, tr: el.parentNode}
                )
              }

              if (real_idx !== (splice_length + min_col - 1)) {
                change_id_arr.push(
                    {
                      before: el,
                      id: next_td_id,
                    }
                )
              }

              if (real_idx === splice_length + min_col - 1) {
                delete_arr.push(
                    {
                      before: el,
                      tr: el.parentNode
                    }
                )
              }
            } else {
              const new_td = document.createElement('td')
              const new_el = document.getElementById(next_td_id)

              new_td.setAttribute('id', real_td_id)
              new_td.addEventListener('click', this.onCellClick)
              new_td.addEventListener('dblclick', this.onCellDoubleClick)
              new_td.addEventListener('contextmenu', this.onCellContextMenu)
              new_td.classList.add('cell')
              insert_before_arr.push(
                  {before: new_el, after: new_td, tr: new_el.parentNode}
              )
            }

          })
        }

        if (rows !== cols_count) return

        change_id_arr.forEach(item => {
          item.before.id = item.id
        })
        insert_before_arr.forEach(item => {
          item.tr.insertBefore(item.after, item.before)
        })
        delete_arr.forEach(item => {
          item.tr.removeChild(item.before)
        })
        this.selection.col_start = left === 0 ? this.selection.col_start + 1 : this.selection.col_start
        this.selection.col_end = left === 0 ? this.selection.col_end + 1 : this.selection.col_end
        this.applyEditColAndRow(this.last_edit_row, min_col)
      }
    },
    addRowToDown(down=0) {
      if (this.selection.row_start && this.selection.col_start) {
        const cols = this.genColNames()
        const rows = this.general_rows
        const tbody = document.getElementById('sprTableTBody')

        let min = this.getMin()
        let max = this.getMax()
        let min_row = down === 0 ? max.row : min.row
        const down_min_row = down === 0 ? min_row + 1 : min_row - 1

        let delete_row = null
        let create_row = null
        let insert_before = null
        const change_id_arr = []

        const down_row_el = document.getElementById(`row-${down_min_row}`)
        let down_row_cols = down_row_el.childNodes.length

        if (down_row_cols !== cols.length) return

        for (let row = min_row; row <= rows; row++) {
          const tr_id = `row-${row}`
          const tr_el = document.getElementById(tr_id)
          const down_row = down === 0 ? row + 1 : row

          if (tr_el) {
            if (row === rows) {
              delete_row = tr_el
            }
            if (row === min_row) {
              const row_element = document.createElement('tr')
              row_element.setAttribute('id', `row-${down_row}`)

              cols.forEach((col, idx) => {
                const cell_element = document.createElement("td");
                cell_element.setAttribute('id', `row-${down_row};cell-int-${idx};cell-letter-${col}`)
                cell_element.addEventListener('click', this.onCellClick)
                cell_element.addEventListener('dblclick', this.onCellDoubleClick)
                cell_element.addEventListener('contextmenu', this.onCellContextMenu)
                let cell_text
                if (idx === 0) {
                  cell_element.classList.add('header-col')
                  cell_element.classList.add('first-col-in-row')
                  cell_element.classList.add('stc-position-left')
                  cell_element.setAttribute('width', '50')
                  cell_element.setAttribute('height', '20')
                  cell_text = document.createTextNode(`${down_row}`);
                  cell_element.appendChild(cell_text);

                  const resize_bar = document.createElement('div')
                  resize_bar.classList.add('row-resize-bar')
                  resize_bar.addEventListener('mousedown', this.onMouseDownRow)
                  cell_element.appendChild(resize_bar);
                } else {
                  cell_element.classList.add('cell')
                  cell_text = document.createTextNode('')
                  cell_element.appendChild(cell_text);
                }
                row_element.appendChild(cell_element);
              })

              create_row = row_element

              insert_before = document.getElementById(`row-${down_row}`)
            }
            if (down === 0) {
              if (row > min_row) {
                change_id_arr.push(
                    {
                      id: `row-${down_row}`,
                      id_old: tr_id,
                      el: tr_el,
                      row_number: down_row
                    }
                )
              }
            } else {
              if (row >= min_row) {
                change_id_arr.push(
                    {
                      id: `row-${down_row + 1}`,
                      id_old: tr_id,
                      el: tr_el,
                      row_number: down_row + 1
                    }
                )
              }
            }

          }
        }

        tbody.removeChild(delete_row)
        tbody.insertBefore(create_row, insert_before)
        change_id_arr.forEach(item => {
          item.el.id = item.id
          Array.from(item.el.childNodes).forEach((td, td_idx) => {
            td.id = td.id.replace(item.id_old, item.id)
            if (td_idx === 0) {
              td.textContent = `${item.row_number}`
            }
          })
        })
        this.selection.row_start = down === 0 ? this.selection.row_start + 1 : this.selection.row_start - 1
        this.selection.row_end = down === 0 ? this.selection.row_end + 1 : this.selection.row_end - 1
        this.applyEditColAndRow(min_row, this.last_edit_col)
      }
    },
    deleteRow() {
      if (this.selection.row_start && this.selection.col_start) {
        const cols = this.genColNames()
        const rows = this.general_rows
        const tbody = document.getElementById('sprTableTBody')

        let min = this.getMin()

        let delete_row = null
        const change_id_arr = []
        let stop = false

        const down_row_el = document.getElementById(`row-${min.row}`)
        let down_row_cols = down_row_el.childNodes.length
        if (down_row_cols !== cols.length) return

        for (let a = 0; a < down_row_el.childNodes.length; a++) {
          const current_el = down_row_el.childNodes[a]
          if (parseInt(current_el.getAttribute('rowspan') || '1') > 1) {
            stop = true
            break
          }
        }

        if (stop) return;

        for (let row = min.row; row <= rows; row++) {
          const tr_id = `row-${row}`
          const tr_el = document.getElementById(tr_id)

          if (tr_el) {
            if (row === min.row) {
              delete_row = tr_el
            }
            if (row > min.row) {
              change_id_arr.push(
                  {
                    id: `row-${row - 1}`,
                    id_old: tr_id,
                    el: tr_el,
                    row_number: row - 1
                  }
              )
            }
          }
        }

        change_id_arr.forEach(item => {
          item.el.id = item.id
          Array.from(item.el.childNodes).forEach((td, td_idx) => {
            td.id = td.id.replace(item.id_old, item.id)
            if (td_idx === 0) {
              td.textContent = `${item.row_number}`
            }
          })
        })

        delete_row.id = delete_row.id.replace(`row-${min.row}`, `row-${rows}`)
        Array.from(delete_row.childNodes).forEach((item, idx) => {
          item.id = item.id.replace(`row-${min.row}`, `row-${rows}`)
          if (idx === 0) {
            item.textContent = `${rows}`
          } else {
            item.textContent = ''
          }
        })
        tbody.insertBefore(delete_row, null)
        this.clearActiveCell()
        this.clearHighlight()

        this.selection.col_start = 0
        this.selection.col_end = 0
        this.selection.row_start = 0
        this.selection.row_end = 0
        this.selection.start = false

        this.applyEditColAndRow(min.row, this.last_edit_col, 0, -1, false, true)
      }
    },
    deleteColumn() {
      if (this.selection.row_start && this.selection.col_start) {
        const rows = this.general_rows
        const cols = this.genColNames()
        let min = this.getMin()

        // let cols_count_prev = 0
        let cols_count_curr = 0

        // const col_for_check_prev = min.col - 1
        const col_for_check_curr = min.col

        for (let row = 1; row <= rows; row++) {
          // const prev_row_td_id = `row-${row === 1 ? row : row - 1};cell-int-${col_for_check_prev};cell-letter-${cols[col_for_check_prev]}`
          const curr_row_td_id = `row-${row};cell-int-${col_for_check_curr};cell-letter-${cols[col_for_check_curr]}`

          // if (document.getElementById(prev_row_td_id)) {
          //   cols_count_prev += 1
          // }
          if (document.getElementById(curr_row_td_id)) {
            cols_count_curr += 1
          }
        }

        // if (cols_count_prev !== rows) return
        if (cols_count_curr !== rows) return

        const replace_arr = []
        const change_id_arr = []

        for (let row = 1; row <= rows; row++) {
          for (let col = min.col; col <= cols.length; col++) {
            const el = document.getElementById(`row-${row};cell-int-${col};cell-letter-${cols[col]}`)

            if (el) {
              const tr = el.parentNode

              if (col === min.col) {
                replace_arr.push(
                    {
                      el: el,
                      tr: tr,
                      id: `row-${row};cell-int-${cols.length - 1};cell-letter-${cols[cols.length - 1]}`
                    }
                )
              }

              if (col >= min.col) {
                change_id_arr.push(
                    {
                      el: el,
                      tr: tr,
                      id: `row-${row};cell-int-${col - 1};cell-letter-${cols[col - 1]}`
                    }
                )
              }
            }
          }
        }

        change_id_arr.forEach(item => {
          item.el.id = item.id
        })
        replace_arr.forEach(item => {
          item.el.id = item.id
          item.el.textContent = ''
          item.el.removeAttribute('style');
          item.tr.insertBefore(item.el, null)
        })

        this.clearActiveCell()
        this.clearHighlight()

        this.selection.col_start = 0
        this.selection.col_end = 0
        this.selection.row_start = 0
        this.selection.row_end = 0
        this.selection.start = false

        this.applyEditColAndRow(this.last_edit_row, min.col, -1, 0, true, false)

        // for (let col = min.col; col < max.col + 1; col++) {
        //   for (let row = 1; row <= rows; row++) {
        //     const real_td_id = `row-${row};cell-int-${col};cell-letter-${cols[col]}`
        //     const el = document.getElementById(real_td_id)
        //
        //     if (el) {
        //       el.removeAttribute('style');
        //       el.textContent = ''
        //     }
        //   }
        // }
      }
    },
    toggleQrInTable(el_id, qr_type='', insert_=true, url='') {
      const row = this.image_table.find(i => i.id === el_id)
      if (row) {
        this.image_table.splice(this.image_table.indexOf(row), 1)
      }
      if (insert_) {
        this.image_table.push(
            {
              id: el_id,
              type: qr_type,
              url: url
            }
        )
      }
    },
    insertQR() {
      if (this.selection.row_start && this.selection.col_start) {
        const letter_cols = this.genColNames()

        let min = this.getMin(), max = this.getMax()
        let last_el = null
        let el_count = 0

        for (let row = min.row; row < max.row + 1; row++) {
          for (let col = min.col; col < max.col + 1; col++) {
            const letter_col = letter_cols[col]
            const td_id = `row-${row};cell-int-${col};cell-letter-${letter_col}`

            const td_el = document.getElementById(td_id)
            if (td_el) {
              last_el = td_el
              el_count += 1
            }
          }
        }

        if (el_count === 1 && last_el) {
          if (last_el.childNodes.length) {
            if (last_el.childNodes[0].tagName === 'DIV') {
              last_el.removeChild(last_el.childNodes[0])
            }
          }

          last_el.classList.add('image-box')
          last_el.setAttribute('image-type', 'qr_organization')
          last_el.setAttribute('image-position-left', '2')
          last_el.setAttribute('image-position-top', '2')
          last_el.setAttribute('image-width', '100')
          last_el.setAttribute('image-height', '100')
          last_el.setAttribute('image-url', '')

          const new_div = document.createElement('div')
          new_div.classList.add('td-image-wrapper')

          const new_div_image = document.createElement('div')
          new_div_image.classList.add('td-image')
          new_div_image.style.width = '100px'
          new_div_image.style.height = '100px'
          new_div_image.style.top = '2px'
          new_div_image.style.left = '2px'
          new_div_image.style.backgroundImage = `url(${this.publicPath}img/icons/no_image.png)`

          new_div.appendChild(new_div_image)
          last_el.appendChild(new_div)

          this.toggleQrInTable(last_el.id, 'qr_organization', true)
        }

        this.applyEditColAndRow(min.row, min.col)
      }
    },
    cellUnion() {
      if (this.selection.row_start && this.selection.col_start) {
        const letter_cols = this.genColNames()
        const selection_array = []
        let first_td_id = null
        let td_text = ''
        let td_style_text = ''

        let del = 0

        let min = this.getMin(), max = this.getMax()

        for (let row = min.row; row < max.row + 1; row++) {
          const row_arr = []
          for (let col = min.col; col < max.col + 1; col++) {

            const letter_col = letter_cols[col]
            const td_id = `row-${row};cell-int-${col};cell-letter-${letter_col}`

            if (!first_td_id) {
              first_td_id = td_id
            }
            //
            const td_el = document.getElementById(td_id)
            if (td_el) {
              if (first_td_id === td_id) {
                td_text = td_el.textContent
                td_style_text = td_el.getAttribute('style')
              }

              const tr_el = td_el.parentNode

              if (tr_el) {
                tr_el.removeChild(td_el)
                del += 1
              }
            }

            row_arr.push(td_id)
          }
          selection_array.push(row_arr)
        }

        selection_array.forEach(row => {
          let last_el = null
          row.forEach(col => {
            const row_id = parseInt(col.split(';')[0].replace('row-', ''))
            let col_id_int = parseInt(col.split(';')[1].replace('cell-int-', ''))
            const tr = document.getElementById(`row-${row_id}`)

            // let idx_left = col_id_int + 1
            //
            // Array.from(tr.childNodes).forEach(node => {
            //   const node_id = parseInt(node.id.split(';')[1].replace('cell-int-', ''))
            //   if (node_id > )
            // })

            for (let idx = 0; idx < tr.childNodes.length; idx ++) {
              const node = tr.childNodes[idx]
              const node_id = parseInt(node.id.split(';')[1].replace('cell-int-', ''))

              if (node_id >= col_id_int) {
                last_el = node
                break
              }
            }

            // for (let idx = 0; idx < tr.childNodes.length; idx ++) {
            //   const i = tr.childNodes[idx]
            //   idx_left -= parseInt(i.getAttribute('colspan') || '1')
            //   if (idx_left === 0) {
            //     last_el = i
            //     break
            //   }
            // }

            const td = document.createElement('td')
            td.setAttribute('id', col)
            td.setAttribute('class', 'cell')
            td.addEventListener('click', this.onCellClick)
            td.addEventListener('dblclick', this.onCellDoubleClick)
            td.addEventListener('contextmenu', this.onCellContextMenu)

            tr.insertBefore(td, last_el)
          })
        })

        if (del > 1) {
          selection_array[0].shift()

          selection_array.forEach(row => {
            row.forEach(col => {
              const td = document.getElementById(col)
              if (td) {
                const tr = td.parentNode
                if (tr) {
                  tr.removeChild(td)
                }
              }
            })
          })
        }

        const colspan = max.col - min.col + 1
        const rowspan = max.row - min.row + 1

        const first_td = document.getElementById(first_td_id)
        first_td.setAttribute('colspan', `${del > 1 ? colspan : 1}`)
        first_td.setAttribute('rowspan', `${del > 1 ? rowspan : 1}`)
        first_td.textContent = td_text
        first_td.removeAttribute('style');
        first_td.setAttribute('style', td_style_text)
        this.applyEditColAndRow(max.row, max.col)
      }

      this.clearActiveCell()
    },
    highlightCellsNew() {
      const letter_cols = this.genColNames()
      this.clearActiveCell()
      let min = this.getMin(), max = this.getMax()
      for (let row = min.row; row < max.row + 1; row++) {
        for (let col = min.col; col < max.col + 1; col++) {
          const letter_col = letter_cols[col]
          const td_id = `row-${row};cell-int-${col};cell-letter-${letter_col}`

          const td_el = document.getElementById(td_id)
          if (td_el) {
            const row_span = parseInt(td_el.getAttribute('rowspan') || '1')
            const col_span = parseInt(td_el.getAttribute('colspan') || '1')

            td_el.classList.add("highlighted")

            if (row === min.row || row + row_span - 1 === min.row) {
              td_el.classList.add("highlighted-top")
            }
            if (row === max.row || row + row_span - 1 === max.row) {
              td_el.classList.add("highlighted-bottom")
            }
            if (col === min.col || col + col_span - 1 === min.col) {
              td_el.classList.add("highlighted-left")
            }
            if (col === max.col || col + col_span  - 1 === max.col) {
              td_el.classList.add("highlighted-right")
            }
          }
        }
      }
    },
    onCellContextMenu(e) {
      e.preventDefault()
      e.stopPropagation()

      let open_cell_context = true

      if (e.target.classList.contains('header-col')
          || e.target.classList.contains('row-resize-bar')
          || e.target.classList.contains('row-resize-col')
      ) return

      if (e.target.classList.contains('td-image-wrapper')
          || e.target.classList.contains('td-image')
      ) {
        open_cell_context = false
      } else {
        if ((e.target.childNodes[0] || {}).tagName === 'DIV') {
          open_cell_context = false
        }
      }

      if (open_cell_context) {
        this.show_context_menu = false
        this.x = e.clientX
        this.y = e.clientY
        this.show_context_menu = true
      } else {
        const min = this.getMin()
        const letter_cols = this.genColNames()
        const el_id = `row-${min.row};cell-int-${min.col};cell-letter-${letter_cols[min.col]}`

        let target_el = e.target

        if (e.target.classList.contains('td-image-wrapper')) {
          target_el = e.target.parentNode
        }

        if (e.target.classList.contains('td-image')) {
          target_el = e.target.parentNode.parentNode
        }

        if (target_el !== document.getElementById(el_id)) return;

        this.image_data.show_context_menu = false
        this.x_image = e.clientX
        this.y_image = e.clientY
        this.image_data.show_context_menu = true

        if (!target_el.getAttribute('image-type')) {
          this.image_data.type = 'qr_organization'
          this.image_data.left = 2
          this.image_data.top = 2
          this.image_data.height = 100
          this.image_data.width = 100
          this.image_data.selected_image = null
          this.image_data.url = ''
        } else {
          this.image_data.type = target_el.getAttribute('image-type')
          this.image_data.url = target_el.getAttribute('image-url')
          this.image_data.left = parseInt(target_el.getAttribute('image-position-left'))
          this.image_data.top = parseInt(target_el.getAttribute('image-position-top'))
          this.image_data.height = parseInt(target_el.getAttribute('image-height'))
          this.image_data.width = parseInt(target_el.getAttribute('image-width'))

          this.file_qr = null

          if (this.image_data.images_select.length) {
            const find = this.image_data.images_select.find(i => i.type === this.image_data.url) || {}
            this.image_data.selected_image = find.value
          } else {
            spreedSheetAPI.select_images()
                .then(response => response.data)
                .then(data => {
                  this.image_data.images_select = data
                })
                .finally(() => {
                  const find = this.image_data.images_select.find(i => i.type === this.image_data.url) || {}
                  this.image_data.selected_image = find.value
                })
          }

        }
      }


    },
    genColNames() {
      const letter = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      // const letter = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
      const cols = Array.from(letter)

      for (let l = 0; l < letter.length; l++) {
        cols.push(`A${letter[l]}`)
      }
      return cols
    },
    onMouseDownCol(e) {
      e.preventDefault()
      if (e.button !== 0) return
      const el = e.target.parentNode
      const col_index = el.getAttribute('col-index')
      const col_group_element = document.getElementById(`col-group-${col_index}`)
      this.start_col_move = true
      this.resize_col_start_position = e.screenX
      this.resize_col_start_width = parseInt(col_group_element.getAttribute('width'))
      this.resize_col_index = col_index
    },
    onMouseDownRow(e) {
      e.preventDefault()
      if (e.button !== 0) return
      const td = e.target.parentNode
      const tr = td.parentNode
      const col_index = parseInt(tr.getAttribute('id').replace('row-', ''))
      this.start_row_move = true
      this.resize_row_start_position = e.screenY
      this.resize_row_start_height = parseInt(td.getAttribute('height'))
      this.resize_row_index = col_index

      // td.setAttribute('init-height', td.getAttribute('height'))
    },
    onMouseUp() {
      if (this.start_col_move) {
        this.start_col_move = false
        this.resize_col_start_position = 0
        this.resize_col_start_width = 0
        this.resize_col_index = null
      } else {
        if (this.start_row_move) {
          this.start_row_move = false
          this.resize_row_start_position = 0
          this.resize_row_start_height = 0
          this.resize_row_index = null
        }
      }
    },
    onMouseMove(e) {
      if (this.start_col_move) {
        const current_position = e.screenX
        const col_group_element = document.getElementById(`col-group-${this.resize_col_index}`)
        const min_position = this.resize_col_start_position - this.resize_col_start_width + 10

        if (current_position >= min_position) {
          const current_width = Math.ceil(current_position - this.resize_col_start_position + this.resize_col_start_width)
          col_group_element.setAttribute('width', `${current_width}`)
        }

        setTimeout(() => {
          this.setPageSizeLimitOnA4()
        }, 200)
      } else {
        if (this.start_row_move) {
          const current_position = e.screenY
          const tr = document.getElementById(`row-${this.resize_row_index}`)
          const td = tr.childNodes[0]
          const min_position = this.resize_row_start_position - this.resize_row_start_height
          if (current_position >= min_position) {
            const current_width = Math.ceil(current_position - this.resize_row_start_position + this.resize_row_start_height)
            td.style.lineHeight = null
            td.setAttribute('height', `${current_width}`)
            td.setAttribute('init-height', `${current_width}`)
            if (current_width <= 20) {
              td.style.lineHeight = `${current_width}px`
            }
          }
        }
      }
    },
    clearActiveCell(cell_id='') {
      Array.from(document.getElementsByClassName('active-header')).forEach(i => {
        i.classList.remove("active-header");
      })
      Array.from(document.getElementsByClassName('active')).forEach(i => {
        if (i.getAttribute('id') !== cell_id) {
          i.classList.remove("active");
        }
      })
    },
    toggleActiveCell(cell_id, el=null) {
      if (cell_id === 'cell-input') return
      this.clearActiveCell(cell_id)

      if (cell_id) {
        const row_idx = parseInt(cell_id.split(';')[0].replace('row-', ''))
        const col_idx = parseInt(cell_id.split(';')[1].replace('cell-int-', ''))

        const header_row = document.getElementById('header-row')
        header_row.children[col_idx].classList.toggle('active-header')
        const current_row = document.getElementById('row-' + row_idx)
        current_row.children[0].classList.toggle('active-header')
        if (el) {
          el.classList.add('active')
        } else {
          const el1 = document.getElementById(cell_id)
          el1.classList.add('active')
        }
      }
    },
    onCellClick(e) {
      let el = e.target
      let el_id = el.getAttribute('id')
      let image = false

      if (!el_id) {
        if (el.classList.contains('td-image')) {
          el = el.parentNode.parentNode
          el_id = el.getAttribute('id')
          image = true
        }

        if (el.classList.contains('td-image-wrapper')) {
          el = el.parentNode
          el_id = el.getAttribute('id')
          image = true
        }
      }

      if (image) {
        let row = parseInt(el_id.split(';')[0].replace('row-', ''))
        let col = parseInt(el_id.split(';')[1].replace('cell-int-', ''))

        this.selection.col_start = col
        this.selection.row_start = row
        this.selection.col_end = col
        this.selection.row_end = row
      }

      this.convertStyleToSettings(el.style, 0)

      this.toggleActiveCell(el_id, el)
      this.clearHighlight()
      this.removeCellInput(el.parentNode.getAttribute('id') || el_id)
      this.setCellHeight(el_id)
    },
    highLightSystemWords(text) {
      let text_copy = text
      const system_words = [
        {
          symbol: '#{page-separator}',
          reg: /#{page-separator}/g,
          color: 'error--text'
        },
        {
          symbol: '#{format_number(2, 2)}',
          reg: /#{format_number\(\d+, *\d+\)}/g,
          color: 'teal--text'
        },
        {
          symbol: '#{format_date("%d.%m.%Y")}',
          reg: /#{format_date\('?"?(?:%\w.?)+'?"?\)}/g,
          color: 'teal--text'
        },
        {
          symbol: '#{empty("")}',
          reg: /#{empty\('?"?.+'?"?\)}/g,
          color: 'teal--text'
        },
      ]

      system_words.forEach(item => {
        const arr = [...text.matchAll(item.reg)]
        arr.forEach(i => {
          const replace_word = i[0]
          text_copy = text_copy.replace(replace_word, `<span class="${item.color}">${replace_word}</span>`)
        })
      })

      return text_copy

    },
    removeCellInput(el_id='') {
      const el = document.getElementById('cell-input')
      if (el) {
        const parent = el.parentNode
        if (parent) {
          const value = el.value
          if (el_id === parent.getAttribute('id')) return
          const parent_id = parent.id
          const row_id = parseInt(parent_id.split(';')[0].replace('row-', ''))
          const col_id = parseInt(parent_id.split(';')[1].replace('cell-int-', ''))
          const rowspan = parseInt(parent.getAttribute('rowspan') || '1') - 1
          const colspan = parseInt(parent.getAttribute('colspan') || '1') - 1

          if (value) {
            this.applyEditColAndRow(row_id + rowspan, col_id + colspan)
          }

          if (value.trim() === '@page_separator') {
            const tr = document.getElementById(`row-${row_id}`)
            if (tr) {
              tr.classList.add('table-row-page-separator')
            }
          } else {
            const tr = document.getElementById(`row-${row_id}`)
            if (tr) {
              tr.classList.remove('table-row-page-separator')
            }
          }

          parent.removeChild(el)
          parent.textContent = value
        }
      }
    },
    setCellHeight(el_id) {
      if (!el_id) return

      let el = null

      if (typeof el_id === "string") {
        el = document.getElementById(el_id)
      }

      if (typeof el_id === 'object') {
        el = el_id
      }

      if (el) {
        const tagName = el.tagName.toLowerCase()

        if (tagName === 'td') {
          const row_id = el.getAttribute('id').split(';')[0].replace('row-', '')
          const first_col_in_row = `row-${row_id};cell-int-0;cell-letter-`
          const first_col_in_row_el = document.getElementById(first_col_in_row)

          if (first_col_in_row_el) {
            let height = `${first_col_in_row_el.offsetHeight - 2}`
            let col_height = first_col_in_row_el.getAttribute('height')

            const init_height = first_col_in_row_el.getAttribute('init-height')
            if (!init_height) {
              first_col_in_row_el.setAttribute('init-height', height)
            }

            first_col_in_row_el.setAttribute('height', init_height)

            height = `${first_col_in_row_el.offsetHeight - 2}`
            if (el.getAttribute('data-image-height')) {
              height = `${el.getAttribute('data-image-height')}`
            }
            col_height = init_height

            if (col_height !== height) {
              first_col_in_row_el.setAttribute('height', height)
              if (height < 20) {
                first_col_in_row_el.style.lineHeight = `${height}px`
              } else {
                first_col_in_row_el.style.lineHeight = null
              }
            }
          }

        }
      }
    },
    onCellDoubleClick(e) {
      const el = e.target
      const el_id = el.getAttribute('id')

      if (!el_id) return;

      if ((el.childNodes[0] || {}).tagName === 'DIV') return;

      const input = document.createElement('input')
      input.setAttribute('type', 'text')
      input.setAttribute('id', 'cell-input')
      input.value = el.textContent

      el.textContent = ''

      el.appendChild(input)
      input.focus()
      input.select()
    },
    getMin(row_=-1, col_=-1) {
      const row_end = row_ === -1 ? this.selection.row_end : row_
      const col_end = col_ === -1 ? this.selection.col_end : col_
      return {
        row: Math.min(this.selection.row_start, row_end),
        col: Math.min(this.selection.col_start, col_end)
      }
    },
    getMax(row_=-1, col_=-1) {
      const row_end = row_ === -1 ? this.selection.row_end : row_
      const col_end = col_ === -1 ? this.selection.col_end : col_
      return {
        row: Math.max(this.selection.row_start, row_end),
        col: Math.max(this.selection.col_start, col_end)
      }
    },
    clearLastEditRowAndCol() {
      Array.from(document.getElementsByClassName('last-edit-row')).forEach(i => {
        i.classList.remove("last-edit-row");
      })
      Array.from(document.getElementsByClassName('last-edit-col')).forEach(i => {
        i.classList.remove("last-edit-col");
      })
    },
    buildSquareTransformMatrix(matrix, letter_cols, old_max_col, old_max_row) {
      const first_matrix = matrix
      const second_matrix = JSON.parse(JSON.stringify(first_matrix))
      let new_span = false
      let new_coordinates = false
      let max_col = 0
      let max_row = 0

      first_matrix.forEach((row, row_idx) => {
        row.forEach((col, col_idx) => {
          second_matrix[row_idx][col_idx].count = 1

          if (second_matrix[row_idx][col_idx].col > max_col) {
            max_col = second_matrix[row_idx][col_idx].col
          }

          if (second_matrix[row_idx][col_idx].row > max_row) {
            max_row = second_matrix[row_idx][col_idx].row
          }

          for (let r = 0; r < col.rowspan; r++) {
            for (let c = 0; c < col.colspan; c++) {
              if (r === 0 && c === 0) {
                second_matrix[row_idx][col_idx].colspan = 0
                second_matrix[row_idx][col_idx].rowspan = 0
              }

              const current_row_index = row_idx + r
              const current_col_index = col_idx + c

              if (current_row_index !== row_idx || current_col_index !== col_idx) {
                if (second_matrix[current_row_index] === undefined) {
                  second_matrix.push([])
                }
                if (second_matrix[current_row_index][current_col_index] === undefined) {
                  const td_id = `row-${col.row + r};cell-int-${col.col + c};cell-letter-${letter_cols[col.col + c]}`
                  const el = document.getElementById(td_id)

                  let rowspan = 0
                  let colspan = 0

                  if (el) {
                    rowspan = parseInt(el.getAttribute('rowspan') || '1')
                    colspan = parseInt(el.getAttribute('colspan') || '1')
                  }

                  if (rowspan > 0 || colspan > 0) {
                    new_span = true
                  }

                  if (col.col + c > max_col) {
                    max_col = col.col + c
                  }

                  if (col.row + r > max_row) {
                    max_row = col.row + r
                  }

                  second_matrix[current_row_index][current_col_index] = {
                    id: `row-${col.row + r};cell-int-${col.col + c};cell-letter-${letter_cols[col.col + c]}`,
                    col: col.col + c,
                    row: col.row + r,
                    rowspan: rowspan,
                    colspan: colspan,
                    count: 1
                  }
                }
              }
            }
          }
        })
      })

      this.fillEmptyCellsInSquare(second_matrix, letter_cols)

      for (let row = 0; row < second_matrix.length; row++) {
        const matrix_row = second_matrix[row]

        const last_col = matrix_row[matrix_row.length - 1]
        if (last_col.col !== max_col) {
          new_coordinates = true
          for (let r = 1; r <= max_col - last_col.col; r++) {
            const col = last_col.col + r
            const td_id = `row-${last_col.row + r};cell-int-${col};cell-letter-${letter_cols[col]}`
            const el = document.getElementById(td_id)

            let rowspan = 0
            let colspan = 0

            if (el) {
              rowspan = parseInt(el.getAttribute('rowspan') || '1')
              colspan = parseInt(el.getAttribute('colspan') || '1')
            }

            if (rowspan > 0 || colspan > 0) {
              new_span = true
            }

            second_matrix[row][matrix_row.length - 1 + r] = {
              id: td_id,
              col: col,
              row: last_col.row,
              rowspan: rowspan,
              colspan: colspan,
              count: 1
            }
          }
        }
      }

      if (new_span || new_coordinates || old_max_col < max_col || old_max_row < max_row) {
        const result = this.buildSquareTransformMatrix(second_matrix, letter_cols, max_col, max_row)
        return {
          max_col: result.max_col,
          max_row: result.max_row,
          matrix: result.matrix
        }
      } else {
        return {
          max_col: max_col,
          max_row: max_row,
          matrix: second_matrix
        }
      }
    },
    fillEmptyCellsInSquare(matrix, letter_cols) {
      for (let r = 0; r < matrix.length; r++) {
        const matrix_row = matrix[r]
        let last_not_empty = null
        for (let c = 0; c < matrix_row.length; c++) {
          if (matrix_row[c]) {
            last_not_empty = c
            break
          }
        }

        for (let c = 0; c < last_not_empty; c++) {
          if (matrix_row[c] === undefined) {
            const last_not_empty_col = matrix_row[last_not_empty]
            const col = last_not_empty_col.col - last_not_empty + c
            const td_id = `row-${last_not_empty_col.row};cell-int-${col};cell-letter-${letter_cols[col]}`

            matrix_row[c] = {
              id: td_id,
              col: col,
              row: last_not_empty_col.row,
              rowspan: 0,
              colspan: 0,
              count: 1
            }
          }
        }
      }
    },
    buildSquare(row_, col_) {
      let min = this.getMin(row_, col_), max = this.getMax(row_, col_)
      const letter_cols = this.genColNames()

      const first_matrix = []

      for (let row = min.row; row < max.row + 1; row++) {
        const matrix_row = []
        for (let col = min.col; col < max.col + 1; col++) {
          const td_id = `row-${row};cell-int-${col};cell-letter-${letter_cols[col]}`
          const el = document.getElementById(td_id)

          let rowspan = 0
          let colspan = 0

          if (el) {
            rowspan = parseInt(el.getAttribute('rowspan') || '1')
            colspan = parseInt(el.getAttribute('colspan') || '1')
          }

          matrix_row.push(
              {
                id: td_id,
                col: col,
                row: row,
                rowspan: rowspan,
                colspan: colspan,
                count: 0
              }
          )
        }
        first_matrix.push(matrix_row)
      }

      const second_matrix = JSON.parse(JSON.stringify(first_matrix))
      let new_span = false
      let new_coordinates = false
      let max_col = 0
      let max_row = 0

      first_matrix.forEach((row, row_idx) => {
        row.forEach((col, col_idx) => {
          second_matrix[row_idx][col_idx].count = 1

          if (second_matrix[row_idx][col_idx].col > max_col) {
            max_col = second_matrix[row_idx][col_idx].col
          }

          if (second_matrix[row_idx][col_idx].row > max_row) {
            max_row = second_matrix[row_idx][col_idx].row
          }

          for (let r = 0; r < col.rowspan; r++) {
            for (let c = 0; c < col.colspan; c++) {
              if (r === 0 && c === 0) {
                second_matrix[row_idx][col_idx].colspan = 0
                second_matrix[row_idx][col_idx].rowspan = 0
              }

              const current_row_index = row_idx + r
              const current_col_index = col_idx + c

              if (current_row_index !== row_idx || current_col_index !== col_idx) {
                if (second_matrix[current_row_index] === undefined) {
                  second_matrix.push([])
                }
                if (second_matrix[current_row_index][current_col_index] === undefined) {
                  const td_id = `row-${col.row + r};cell-int-${col.col + c};cell-letter-${letter_cols[col.col + c]}`
                  const el = document.getElementById(td_id)

                  let rowspan = 0
                  let colspan = 0

                  if (el) {
                    rowspan = parseInt(el.getAttribute('rowspan') || '1')
                    colspan = parseInt(el.getAttribute('colspan') || '1')
                  }

                  if (rowspan > 0 || colspan > 0) {
                    new_span = true
                  }

                  if (col.col + c > max_col) {
                    max_col = col.col + c
                  }

                  if (col.row + r > max_row) {
                    max_row = col.row + r
                  }

                  second_matrix[current_row_index][current_col_index] = {
                    id: `row-${col.row + r};cell-int-${col.col + c};cell-letter-${letter_cols[col.col + c]}`,
                    col: col.col + c,
                    row: col.row + r,
                    rowspan: rowspan,
                    colspan: colspan,
                    count: 1
                  }
                }
              }
            }
          }
        })
      })

      for (let row = 0; row < second_matrix.length; row++) {
        const matrix_row = second_matrix[row]

        const last_col = matrix_row[matrix_row.length - 1]
        if (last_col.col !== max_col) {
          new_coordinates = true
          for (let r = 1; r <= max_col - last_col.col; r++) {
            const col = last_col.col + r
            const td_id = `row-${last_col.row};cell-int-${col};cell-letter-${letter_cols[col]}`
            const el = document.getElementById(td_id)

            let rowspan = 0
            let colspan = 0

            if (el) {
              rowspan = parseInt(el.getAttribute('rowspan') || '1')
              colspan = parseInt(el.getAttribute('colspan') || '1')
            }

            if (rowspan > 0 || colspan > 0) {
              new_span = true
            }

            second_matrix[row][matrix_row.length - 1 + r] = {
              id: td_id,
              col: col,
              row: last_col.row,
              rowspan: rowspan,
              colspan: colspan,
              count: 1
            }
          }
        }
      }

      if (new_span || new_coordinates) {
        this.fillEmptyCellsInSquare(second_matrix, letter_cols)
        const result = this.buildSquareTransformMatrix(second_matrix, letter_cols, max_col, max_row)
        max_col = result.max_col
        max_row = result.max_row
      }

      let result_col_min = 0
      let result_col_max = 0
      let result_row_min = 0
      let result_row_max = 0

      if (this.selection.col_start === min.col) {
        result_col_min = min.col
        result_col_max = max_col
      } else {
        result_col_min = max_col
        result_col_max = min.col
      }

      if (this.selection.row_start === min.row) {
        result_row_min = min.row
        result_row_max = max_row
      } else {
        result_row_min = max_row
        result_row_max = min.row
      }

      return {
        col_min: result_col_min,
        col_max: result_col_max,
        row_min: result_row_min,
        row_max: result_row_max
      }

    },
    clearHighlight() {
      Array.from(document.getElementsByClassName('highlighted')).forEach(i => {
        i.classList.remove("highlighted");
        i.classList.remove("highlighted-top");
        i.classList.remove("highlighted-bottom");
        i.classList.remove("highlighted-right");
        i.classList.remove("highlighted-left");
      })
    },
    onStartDrag(e) {
      if (e.button !== 0) return
      const e_id = e.target.getAttribute('id')
      if (e_id === 'cell-input') return;
      this.clearActiveCell(e_id)
      if (!e_id) return;
      let row = parseInt(e_id.split(';')[0].replace('row-', ''))
      let col = parseInt(e_id.split(';')[1].replace('cell-int-', ''))
      if (col === 0 || row === 0) return;
      this.selection.col_start = col
      this.selection.row_start = row
      this.selection.start = true
    },
    onStopDrag(e) {
      if (!this.selection.start) return
      const td = e.target
      const e_id = td.getAttribute('id')
      let row = parseInt(e_id.split(';')[0].replace('row-', ''))
      let col = parseInt(e_id.split(';')[1].replace('cell-int-', ''))
      const {col_max, row_max} = this.buildSquare(row, col)
      this.selection.col_end = col_max
      this.selection.row_end = row_max
      this.selection.start = false
    },
    onDrag(e) {
      if (e.target.classList.contains('first-row-in-row')
          || e.target.classList.contains('first-col-in-row')
          || e.target.classList.contains('stc-position-top')
          || e.target.classList.contains('stc-position-left')
          || e.target.classList.contains('col-resize-bar')
          || e.target.classList.contains('row-resize-bar')
      ) return
      if (this.selection.start && e.target.tagName === 'TD' && e.target !== this.selection.last_known_cell ) {
        const td = e.target
        const e_id = td.getAttribute('id')

        let row = parseInt(e_id.split(';')[0].replace('row-', ''))
        let col = parseInt(e_id.split(';')[1].replace('cell-int-', ''))

        if (this.selection.row_start > 0 && row > 0 && this.selection.col_start > 0 && col > 0) {
          const {col_max, row_max} = this.buildSquare(row, col)

          if (this.selection.row_start > row_max || this.selection.col_start > col_max) {
            this.selection.col_start = 0
            this.selection.col_end = 0
            this.selection.row_start = 0
            this.selection.row_end = 0
            this.clearHighlight()
          } else {
            this.selection.col_end = col_max
            this.selection.row_end = row_max
            this.selection.last_known_cell = e.target

            this.clearHighlight()
            this.highlightCellsNew()
          }

        } else {
          this.selection.col_start = 0
          this.selection.col_end = 0
          this.selection.row_start = 0
          this.selection.row_end = 0
          this.clearHighlight()
        }

      }
    },
    createEmptyTable() {
        const colgroup = document.getElementById('sprTableColGroup')
        const tbody = document.getElementById('sprTableTBody')
        const cols = this.genColNames()
        const rows = this.general_rows

        const first_row = document.createElement('tr')
        first_row.classList.add('top-row')

        const second_row = document.createElement('tr')
        second_row.setAttribute('id', 'header-row')

        cols.forEach((col, idx) => {
          const col_element = document.createElement('col')
          col_element.setAttribute('width', `${idx === 0 ? 50 : 80}`)
          col_element.setAttribute('id', `col-group-${idx}`)
          colgroup.appendChild(col_element)

          const first_cell = document.createElement('td')
          first_cell.setAttribute('width', `${idx === 0 ? 50 : 80}`)
          first_cell.setAttribute('height', '1')
          first_cell.classList.add('stc-position-top')
          first_row.appendChild(first_cell)

          const second_cell = document.createElement('td')
          second_cell.setAttribute('width', `${idx === 0 ? 50 : 80}`)
          second_cell.setAttribute('height', '20')
          second_cell.setAttribute('col-index', `${idx}`)
          second_cell.classList.add('stc-position-top')
          second_cell.classList.add('first-row-in-row')

          const second_cell_text = document.createTextNode(`${col}`);
          const resize_bar = document.createElement('span')
          resize_bar.classList.add('col-resize-bar')
          resize_bar.style.height = '15px'
          resize_bar.addEventListener('mousedown', this.onMouseDownCol)
          second_cell.appendChild(second_cell_text)
          second_cell.appendChild(resize_bar)
          second_row.appendChild(second_cell)
        })

        tbody.appendChild(first_row)
        tbody.appendChild(second_row)

        for (let row = 1; row <= rows; row++) {
          const row_element = document.createElement('tr')
          row_element.setAttribute('id', `row-${row}`)

          cols.forEach((col, idx) => {
            const cell_element = document.createElement("td");
            cell_element.setAttribute('id', `row-${row};cell-int-${idx};cell-letter-${col}`)
            cell_element.addEventListener('click', this.onCellClick)
            cell_element.addEventListener('dblclick', this.onCellDoubleClick)
            cell_element.addEventListener('contextmenu', this.onCellContextMenu)
            let cell_text
            if (idx === 0) {
              cell_element.classList.add('header-col')
              cell_element.classList.add('first-col-in-row')
              cell_element.classList.add('stc-position-left')
              cell_element.setAttribute('width', '50')
              cell_element.setAttribute('height', '20')
              cell_text = document.createTextNode(`${row}`);
              cell_element.appendChild(cell_text);

              const resize_bar = document.createElement('div')
              resize_bar.classList.add('row-resize-bar')
              resize_bar.addEventListener('mousedown', this.onMouseDownRow)
              cell_element.appendChild(resize_bar);
            } else {
              cell_element.classList.add('cell')
              cell_text = document.createTextNode('')
              cell_element.appendChild(cell_text);
            }
            row_element.appendChild(cell_element);
          })
          tbody.appendChild(row_element)
        }

        const table = document.getElementById('sprTable')
        table.addEventListener('mousemove', this.onMouseMove)
        table.addEventListener('mouseup', this.onMouseUp)

        this.setPageSizeLimitOnA4()
        },
    buildHistory(build_payload=false) {
      const letters = this.genColNames()
      const history_object = {
        last_edit_row: this.last_edit_row,
        last_edit_col: this.last_edit_col,
        selection: {
          row_start: this.selection.row_start,
          row_end: this.selection.row_end,
          col_start: this.selection.col_start,
          col_end: this.selection.col_end,
        }
      }
      const local_history = []
      const local_col_group = []

      for (let row = 1; row < this.last_edit_row; row++) {
        const history_row = []
        let col_height = 0
        let col_style = ''

        for (let col = 0; col < this.last_edit_col; col++) {
          const td_id = `row-${row};cell-int-${col};cell-letter-${letters[col]}`
          const el = document.getElementById(td_id)

          if (row === 1) {
            const col_group = document.getElementById(`col-group-${col}`)
            local_col_group.push(
                {
                  id: col_group.getAttribute('id'),
                  width: col_group.getAttribute('width')
                }
            )
          }

          if (col === 0) {
            col_height = parseInt(el.getAttribute('height') || '22')
            col_style = el.getAttribute('style')
          }

          if (el) {
            const local_el = {
              id: td_id,
              rowspan: el.getAttribute('rowspan'),
              colspan: el.getAttribute('colspan'),
              textContent: el.textContent,
              style: el.getAttribute('style'),
              class_: el.getAttribute('class'),
              height: el.getAttribute('height'),
              init_height: el.getAttribute('init-height'),
              width: el.getAttribute('width'),
              image_type: el.getAttribute('image-type'),
              image_url: el.getAttribute('image-url'),
              image_position_left: el.getAttribute('image-position-left'),
              image_position_top: el.getAttribute('image-position-top'),
              image_width: el.getAttribute('image-width'),
              image_height: el.getAttribute('image-height'),
              col_height,
              col_style
            }
            history_row.push(
                local_el
            )
          }
        }
        local_history.push(history_row)
      }

      history_object.table = local_history
      history_object.col_group = local_col_group

      if (!build_payload) {
        let current_history_row = null

        if (this.current_history_index === -1 || this.current_history_index === this.history.length - 1) {
          this.history.push(history_object)
        } else {
          current_history_row = this.history[this.current_history_index]

          this.history.splice(this.current_history_index + 1, 0, history_object)
        }

        this.history = this.history.slice(-this.history_max_length)
        if (current_history_row) {
          this.current_history_index = this.history.indexOf(current_history_row)
        }

        this.history_arr_length = this.history.length
      } else {
        return history_object
      }

    },
    doBuildHistory() {
      this.debounceFn()
    },
    restoreHistoryRow(history_row, restore=false) {
      const letters = this.genColNames()

      this.clearActiveCell()
      this.clearHighlight()
      this.clearLastEditRowAndCol()

      if (history_row) {
        for (let row = 1; row < history_row.last_edit_row; row++) {
          for (let col = 1; col < history_row.last_edit_col + 1; col++) {
            const td_id = `row-${row};cell-int-${col};cell-letter-${letters[col]}`
            const el = document.getElementById(td_id)

            if (col === 1) {
              const col_group = document.getElementById(`col-group-${row}`)
              col_group.setAttribute('width', '80')
            }

            if (el) {
              if (col < history_row.last_edit_col) {
                const tr = el.parentNode
                tr.removeChild(el)
              }
            }
          }
        }

        if (restore) {
          history_row.table.forEach(row => {
            let last_el = null
            row.forEach((col, col_idx) => {
              if (col_idx > 0) {
                const row_id = parseInt(col.id.split(';')[0].replace('row-', ''))
                let col_id_int = parseInt(col.id.split(';')[1].replace('cell-int-', ''))
                const tr = document.getElementById(`row-${row_id}`)

                for (let idx = 0; idx < tr.childNodes.length; idx ++) {
                  const node = tr.childNodes[idx]
                  const node_id = parseInt(node.id.split(';')[1].replace('cell-int-', ''))

                  if (node_id >= col_id_int) {
                    last_el = node
                    break
                  }
                }

                const td = document.createElement('td')
                td.setAttribute('id', col.id)
                td.setAttribute('class', 'cell')
                if (col.rowspan) {
                  td.setAttribute('rowspan', `${col.rowspan}`)
                }
                if (col.colspan) {
                  td.setAttribute('colspan', `${col.colspan}`)
                }
                if (col.textContent) {
                  td.textContent = col.textContent
                }
                if (col.style) {
                  td.setAttribute('style', col.style)
                }
                if (col.class_) {
                  td.setAttribute('class', col.class_)
                }
                if (col.image_type) {
                  td.setAttribute('image-type', col.image_type)
                  td.setAttribute('image-url', col.image_url)
                  td.setAttribute('image-position-left', col.image_position_left)
                  td.setAttribute('image-position-top', col.image_position_top)
                  td.setAttribute('image-width', col.image_width)
                  td.setAttribute('image-height', col.image_height)
                  td.classList.add('image-box')

                  const new_div = document.createElement('div')
                  new_div.classList.add('td-image-wrapper')

                  const new_div_image = document.createElement('div')
                  new_div_image.classList.add('td-image')
                  new_div_image.style.width = `${col.image_width}px`
                  new_div_image.style.height = `${col.image_height}px`
                  new_div_image.style.top = `${col.image_position_top}${col.image_position_top > 0 ? 'px' : ''}`
                  new_div_image.style.left = `${col.image_position_left}${col.image_position_left > 0 ? 'px' : ''}`
                  new_div_image.style.backgroundImage = `url(${this.publicPath}img/icons/no_image.png)`

                  new_div.appendChild(new_div_image)
                  td.appendChild(new_div)
                }

                if (col.textContent.trim() === '@page_separator') {
                    tr.classList.add('table-row-page-separator')
                }

                td.addEventListener('click', this.onCellClick)
                td.addEventListener('dblclick', this.onCellDoubleClick)
                td.addEventListener('contextmenu', this.onCellContextMenu)

                tr.insertBefore(td, last_el)
              } else {
                const td = document.getElementById(col.id)
                if (col.height) {
                  td.setAttribute('height', `${col.height}`)
                  if (col.col_style) {
                    td.setAttribute('style', col.col_style)
                  }
                }
                if (col.width) {
                  td.setAttribute('width', `${col.width}`)
                }
                if (col.init_height) {
                  td.setAttribute('init-height', `${col.init_height}`)
                }
              }
            })
          })
          history_row.col_group.forEach(col => {
            const el = document.getElementById(col.id)
            if (el) {
              el.setAttribute('width', col.width)
            }
          })

        } else {
          for (let row = 1; row < history_row.last_edit_row; row++) {
            let last_el = null
            for (let col = 1; col < history_row.last_edit_col; col++) {
              const tr = document.getElementById(`row-${row}`)
              const td_id = `row-${row};cell-int-${col};cell-letter-${letters[col]}`

              if (col === 1) {
                const col_group = document.getElementById(`col-group-${row}`)
                col_group.setAttribute('width', '80')
              }

              for (let idx = 0; idx < tr.childNodes.length; idx ++) {
                const node = tr.childNodes[idx]
                const node_id = parseInt(node.id.split(';')[1].replace('cell-int-', ''))

                if (node_id >= col) {
                  last_el = node
                  break
                }
              }

              const td = document.createElement('td')
              td.setAttribute('id', td_id)
              td.setAttribute('class', 'cell')
              td.addEventListener('click', this.onCellClick)
              td.addEventListener('dblclick', this.onCellDoubleClick)
              td.addEventListener('contextmenu', this.onCellContextMenu)

              tr.insertBefore(td, last_el)
            }
          }
        }

        this.last_edit_col = history_row.last_edit_col
        this.last_edit_row = history_row.last_edit_row
        this.selection.col_end = history_row.selection.col_end
        this.selection.col_start = history_row.selection.col_start
        this.selection.row_start = history_row.selection.row_start
        this.selection.row_end = history_row.selection.row_end

        for (let c = 1; c <= this.last_edit_col - 1; c++) {
          const col_el = document.getElementById(`row-${this.last_edit_row};cell-int-${c};cell-letter-${letters[c]}`)
          if (col_el) {
            col_el.classList.add('last-edit-row')
          }
        }

        for (let r = 1; r <= this.last_edit_row - 1; r++) {
          const col_el = document.getElementById(`row-${r};cell-int-${this.last_edit_col};cell-letter-${letters[this.last_edit_col]}`)
          if (col_el) {
            col_el.classList.add('last-edit-col')
          }
        }
      }
    },
    setCurrentHistoryIndexLeft() {
      if (this.current_history_index === -1) {
        if (this.history.length) {
          this.current_history_index = this.history.length - 1
        }
      } else {
        if (this.history[this.current_history_index - 1]) {
          this.current_history_index -= 1
        }
      }

      if (this.current_history_index === -1) return

      this.restoreFromHistory(true)
    },
    setCurrentHistoryIndexRight() {
      const prev_index = this.current_history_index

      if (this.current_history_index === -1) {
        if (this.history.length) {
          this.current_history_index = this.history.length
        }
      } else {
        if (this.history[this.current_history_index + 1]) {
          this.current_history_index += 1
        }
      }

      if (this.current_history_index === -1) return

      this.restoreFromHistory(false, prev_index)
    },
    restoreFromHistory(left=true, prev=-1) {
      let local_index = this.current_history_index
      let right_last = false

      if (!left) {
        local_index -= 1
      }

      if (prev === this.history.length - 1 && this.current_history_index === this.history.length - 1) {
        right_last = true
      }

      if (local_index === -1) return

      let delete_row_index = 0
      let restore_row_index = 0

      if (left) {
        delete_row_index = local_index
        restore_row_index = local_index - 1
      } else {
        if (right_last) {
          delete_row_index = local_index + 1
          restore_row_index = local_index + 1
        } else {
          delete_row_index = local_index + 1
          restore_row_index = local_index
        }
      }

      const delete_row =  this.history[delete_row_index]
      const restore_row =  this.history[restore_row_index]

      if (delete_row) {
        this.restoreHistoryRow(delete_row, false)
      }
      if (restore_row && delete_row) {
        this.restoreHistoryRow(restore_row, true)
      }

      if (right_last) {
        this.current_history_index = -1
      }
    },
    onFieldSelect(payload) {
      let field_value = this.field_selection.current_field_value.replaceAll(payload.value, '').trim()
      if (field_value.length) {
        field_value += ` {${payload.value}}`
      } else {
        field_value = `{${payload.value}}`
      }

      this.field_selection.current_field_value = field_value
    },
    showFieldSelect(e) {
      e.preventDefault()
      e.stopPropagation()
      const id = e.target.id.replace('field-for-select-', '')
      this.field_selection.show_field_menu = false
      this.x_field = e.clientX
      this.y_filed = e.clientY
      this.field_selection.show_field_menu = true
      this.field_selection.row_num = parseInt(id)
      this.field_selection.current_field_value = e.target.value
    },
    saveFieldSelect() {
      const row = this.parameters_table.find(i => i.row_num === this.field_selection.row_num)
      if (row) {
        row.value = this.field_selection.current_field_value
      }
      this.field_selection.show_field_menu = false
      this.field_selection.current_field_value = ''
      this.field_selection.current_group = ''
      this.field_selection.row_num = null
    },
    showFormatSelect(e) {
      e.preventDefault()
      e.stopPropagation()
      const id = e.target.id.replace('field-for-format-', '')
      this.field_selection.show_field_menu = false
      this.x_format = e.clientX
      this.y_format = e.clientY
      this.format_selection.show_field_menu = true
      this.format_selection.row_num = parseInt(id)
      this.format_selection.current_field_value = e.target.value
    },
    saveFormatSelect() {
      const row = this.parameters_table.find(i => i.row_num === this.format_selection.row_num)
      if (row) {
        row.format = this.format_selection.current_field_value
      }
      this.format_selection.show_field_menu = false
      this.format_selection.current_field_value = ''
      this.format_selection.row_num = null
      this.format_selection.date_format_value = null
      this.format_selection.date_format_text = null
      this.format_selection.boolean_false_value = null
      this.format_selection.boolean_true_value = null
      this.format_selection.number_format_precision = null
    },
    showEmptySelect(e) {
      e.preventDefault()
      e.stopPropagation()
      const id = e.target.id.replace('field-for-empty-', '')
      this.empty_selection.show_field_menu = false
      this.x_empty = e.clientX
      this.y_empty = e.clientY
      this.empty_selection.show_field_menu = true
      this.empty_selection.row_num = parseInt(id)
      this.empty_selection.current_field_value = e.target.value
    },
    saveEmptySelect() {
      const row = this.parameters_table.find(i => i.row_num === this.empty_selection.row_num)
      if (row) {
        row.empty = this.empty_selection.current_field_value
      }
      this.empty_selection.show_field_menu = false
      this.empty_selection.current_field_value = ''
      this.empty_selection.row_num = null
      this.empty_selection.empty_value = null
    },
    showAdditionalSelect(e) {
      e.preventDefault()
      e.stopPropagation()
      const id = e.target.id.replace('field-for-additional-', '')
      this.additional_selection.show_field_menu = false
      this.x_additional = e.clientX
      this.y_additional = e.clientY
      this.additional_selection.show_field_menu = true
      this.additional_selection.row_num = parseInt(id)
      this.additional_selection.current_field_value = e.target.value
    },
    saveAdditionalSelect() {
      const row = this.parameters_table.find(i => i.row_num === this.additional_selection.row_num)
      if (row) {
        row.additional = this.additional_selection.current_field_value
      }
      this.additional_selection.show_field_menu = false
      this.additional_selection.current_field_value = ''
      this.additional_selection.row_num = null
    },
    setPageSizeLimitOnA4() {
      const col_groups = document.getElementById('sprTableColGroup')
      let calc_width = 0
      let last_element = null

      for (let i=1; i < col_groups.childNodes.length;i++) {
        const width = parseInt(col_groups.childNodes[i].getAttribute('width'))
        calc_width += width

        if (calc_width > 780) {
          last_element = col_groups.childNodes[i - 1]
          break
        }
      }

      Array.from(document.getElementsByClassName('page-border-for-a4')).forEach(i => {
        i.classList.remove("page-border-for-a4");
      })

      if (last_element) {
        last_element.classList.add('page-border-for-a4')
      }
    }
  },
  created() {
    this.history = []
    this.appWidth = window.innerWidth
    this.appHeight = window.innerHeight
    window.addEventListener("resize", this.onResize);
    this.$nextTick(() => {
      const el = document.getElementById('border-settings-button')
      this.onTableKyeUp()
      this.onClickOutside(el, () => {
        this.border_settings.show = false
        this.border_settings.all.show_border_style = false
        this.border_settings.all.show_border_color = false
        this.border_settings.around.show_border_style = false
        this.border_settings.around.show_border_color = false
        this.border_settings.top.show_border_style = false
        this.border_settings.top.show_border_color = false
        this.border_settings.right.show_border_style = false
        this.border_settings.right.show_border_color = false
        this.border_settings.bottom.show_border_style = false
        this.border_settings.bottom.show_border_color = false
        this.border_settings.left.show_border_style = false
        this.border_settings.left.show_border_color = false
      })
      this.debounceFn = debounce(() => this.buildHistory(), 500)
    })
  }
}
</script>

<style scoped lang="scss">
:deep(.v-input.v-textarea) {
  textarea {
    line-height: 1.28rem;
    padding-bottom: 10px;
    overflow: auto;
    resize: vertical !important;
  }
}
.default-font-button {
  min-width: 20px !important;
  height: 30px !important;
  width: 30px !important;
  margin-right: 6px !important;
  display: flex;
  align-items: center;

  .border-settings {
    width: 200px;
    position: absolute;
    top: 31px;
    left: 0;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 4px;

    .border-settings-row {
      display: flex;
      align-items: center;
      position: relative;
      padding-top: 4px;
      padding-bottom: 4px;

      .border-settings-icon {
        flex: 0 0 30px;
        border-radius: 4px;
        height: 24px;
        display: flex;
        align-items: center;
        margin-left: 3px;
        margin-right: 10px;
      }

      .color-square-wrapper {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: 1px solid #c5c5c5;
        overflow: hidden;

        .color-square {
          width: 17px;
          height: 17px;
        }
      }

      .border-style-div {
        position: relative;
        border: 1px solid #cbcbcb;
        border-radius: 4px;
        margin-right: 10px;

        .border-style-line {
          width: 114px;
          height: 18px;
          border-radius: 4px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          padding: 4px;
        }

        .border-style-options {
          width: 114px;
          position: absolute;
          top: 19px;
          left: 8px;
          background-color: #ffffff;
          border-radius: 4px;
          z-index: 10;

          div {
            text-align: center;
            font-size: 14px;
            line-height: 29px;
            cursor: pointer;

            &:hover {
              background-color: #dadada;
            }
          }
        }
      }
    }
  }
}
.default-form-button {
  height: 30px !important;
  min-width: 100px !important;
  margin-right: 6px !important;
}
.default-font-button-color {
  min-width: 20px !important;
  height: 30px !important;
  width: 60px !important;
  margin-right: 6px !important;

  .color-square {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-left: 8px;
  }
}
.font-size-input {
  width: 40px;
  height: 30px;
  position: relative;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
  }

  .input-options {
    width: 40px;
    position: absolute;
    top: 31px;
    left: 0;
    background-color: #ffffff;
    border-radius: 4px;

    div {
      text-align: center;
      font-size: 14px;
      line-height: 29px;
      cursor: pointer;

      &:hover {
        background-color: #dadada;
      }
    }
  }
}
.font-family-input {
  width: 120px;
  height: 30px;
  position: relative;
  margin-left: 6px;

  .font-family-title {
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    line-height: 29px;
  }

  .font-options {
    width: 100%;
    position: absolute;
    top: 31px;
    left: 0;
    background-color: #ffffff;
    border-radius: 4px;

    div {
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      line-height: 29px;
      cursor: pointer;

      &:hover {
        background-color: #dadada;
      }
    }
  }
}
:deep(#sprTableColGroup) {
  .page-border-for-a4 {
    border-right: 1px solid #a1a1a1 !important;
  }
}
:deep(#sprTableTBody) {
  #cell-input {
    outline: none;
    height: 100%;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    color: black;
    position: relative;
  }

  overflow: scroll;

  tr {
    user-select: none;
  }

  tr.table-row-page-separator {
    border-bottom: 1px solid var(--v-success-lighten1);
  }

  td {
    overflow: hidden;
    word-wrap: break-word;

    .td-image-wrapper {
      position: relative;

      .td-image {
        position: absolute;
        background-size: contain;
      }
    }
  }

  .stc-position-top {
    position: sticky !important;
    top: 0;
    z-index: 2;
  }

  .stc-position-left {
    position: sticky !important;
    left: 0;
  }

  .col-resize-bar {
    background: 0 0;
    cursor: col-resize;
    float: right;
    margin-right: -3px;
    width: 5px;

    &:hover {
      background: silver;
    }
  }
  .row-resize-bar {
    background: 0 0;
    cursor: row-resize;
    height: 5px;
    width: 100%;
    bottom: -3px;
    z-index: 9;
    position: absolute;

    &:hover {
      background: silver;
    }
  }

  .first-col-in-row {
    position: relative;
    overflow: visible;
    text-align: center;
    vertical-align: top;
    color: rgb(40, 40, 40);
    background-color: rgb(255, 255, 255);
    direction: rtl;
    border-right: 1px solid rgb(218, 218, 218);
    border-bottom: 1px solid rgb(218, 218, 218);
    font-size: 12px;
    padding: 2px 2px 1px;
  }

  .first-row-in-row {
    overflow: visible;
    text-align: center;
    color: rgb(40, 40, 40);
    background-color: rgb(255, 255, 255);
    border-right: 1px solid rgb(218, 218, 218);
    border-bottom: 1px solid rgb(218, 218, 218);
    font-size: 12px;
    padding: 2px 2px 1px;
  }

  .active-header {
    background-color: rgb(255, 249, 231);
  }

  .cell.active {
    outline: 2px solid #f7b528;
    outline-offset: 1px;
  }

  .cell {
    padding: 2px 2px 1px;
    vertical-align: top;
    font-size: small;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    text-align: left;
    border: 1px ridge rgb(245, 245, 245);
    //border-bottom: 0.5px solid rgb(232, 232, 232);
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .highlighted {
    background-color: rgb(255, 249, 231);
    color: #000;
    box-sizing: border-box;
  }
  .highlighted-top {
    border-top: 2px solid #f7b528;
  }
  .highlighted-right {
    border-right: 2px solid #f7b528;
  }
  .highlighted-bottom {
    border-bottom: 2px solid #f7b528;
  }
  .highlighted-left {
    border-left: 2px solid #f7b528;
  }
  .last-edit-row {
    box-sizing: border-box;
    border-top: 1px dashed #f6b1b2;
  }
  .last-edit-col {
    box-sizing: border-box;
    border-left: 1px dashed #f6b1b2;
  }
}
.field-card {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;

  &.field-card-header {
    background-color: #f3f3f3;

    .field-row {
      & > div {
        font-weight: bold !important;
        font-size: .73rem !important;
        color: #3f3f3f !important;
      }
    }
  }

  &:nth-child(1) {
    margin-top: 4px;
  }

  .field-row {
    display: flex;
    flex-wrap: nowrap;
    font-size: .76rem;
    align-items: center;

    & > div {
      padding: 3px;
    }

    & > div {
      & > input {
        text-align: inherit;
        padding: 2px 4px;
        outline: none;
        width: 92%;
        height: 100%;
        border-bottom: 1px solid var(--v-success-base);
      }

      & > .input-error {
        border-bottom: 1px solid var(--v-error-base);
      }

      &:nth-child(1) {
        flex: 0 0 200px;
        overflow: hidden;
        padding-left: 8px;
        font-weight: bold;
        color: var(--v-success-darken2)
      }

      &:nth-child(2) {
        flex: 0 0 70px;
        font-weight: bold;
      }

      &:nth-child(3) {
        flex: 1;
      }

      &:nth-child(4) {
        flex: 0 0 200px;
      }

      &:nth-child(5) {
        flex: 0 0 150px;
      }

      &:nth-child(6) {
        flex: 0 0 160px;
      }

      &:nth-child(7) {
        flex: 0 0 34px;
      }
    }
  }

  .always-enable {
    pointer-events: all !important;
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important;
  }
}
.table-small-select {
  :deep(input) {
    line-height: 12px !important;
    padding: 0 !important;
  }

  :deep(.v-select__selection.v-select__selection--comma) {
    margin: 0 !important;
  }

  :deep(.v-input__icon) {
    width: 20px !important;
    height: 20px !important;
  }

  :deep(.v-input__control > .v-input__slot:before) {
    border-color: var(--v-success-base);
  }
}
.table-small-select.input-error {
  :deep(.v-input__control > .v-input__slot:before) {
    border-color: var(--v-error-base) !important;
  }
}

</style>